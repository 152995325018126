<template>
<div>
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="Admin" to="/admin/home" />
      <q-breadcrumbs-el label="JS Errors" to="/admin/js-errors/list/1" />
      <q-breadcrumbs-el :label="$route.params.recId" />
      <q-breadcrumbs-el label="View">
        <q-btn dense flat color="red" size="sm" icon="refresh" @click="refreshRec(true)" />
      </q-breadcrumbs-el>
    </q-breadcrumbs>

<div v-if="rec">
<div class="row q-my-sm" >
  <div class="col-12">
    <q-chip square dense clickable @click="fieldSet('handled', !rec.handled)" >
      <q-avatar v-show="rec.handled" color="green" text-color="white" icon="check" />
      <q-avatar v-show="!rec.handled" color="red" text-color="white" icon="close" />
      <span v-show="!rec.handled">Not</span>&nbsp;
      Handled
    </q-chip>

  </div>

</div>

<div class="row q-my-xl">
  <table class="table table-striped table-sm full-width">

    <tr>
      <th style="width: 180px;" class="text-left">Type</th>
      <td>
          {{ rec.type }}
      </td>
    </tr>

    <tr>
      <th style="width: 180px;" class="text-left">URL</th>
      <td>
          {{ rec.url }}
      </td>
    </tr>

    <tr>
      <th style="width: 180px;" class="text-left">User</th>
      <td>
        <user-name :user="rec.jwt" show-id show-email />
      </td>
    </tr>

    <tr>
      <th style="width: 180px;" class="text-left">Created On</th>
      <td>
       {{rec.error_time | date('MMM D, YYYY hh:mma')}}
      </td>
    </tr>

    <tr>
      <th style="width: 180px;" class="text-left">Reviewed</th>
      <td>
          <q-chip square dense clickable class="q-my-none" @click="fieldSet('reviewed', !rec.reviewed)" >
            <q-avatar v-show="rec.reviewed" color="green" text-color="white" icon="check" />
            <q-avatar v-show="!rec.reviewed" color="red" text-color="white" icon="close" />
            <span v-show="!rec.reviewed">Not</span>&nbsp;
            Reviewed
          </q-chip>
      </td>
    </tr>


    <tr>
      <th style="width: 180px;" class="text-left">Handled</th>
      <td>
          <q-chip square dense clickable class="q-my-none" @click="fieldSet('handled', !rec.handled)" >
            <q-avatar v-show="rec.handled" color="green" text-color="white" icon="check" />
            <q-avatar v-show="!rec.handled" color="red" text-color="white" icon="close" />
            <span v-show="!rec.handled">Not</span>&nbsp;
            Handled
          </q-chip>
      </td>
    </tr>

    <tr>
      <th style="width: 180px;" class="text-left">Requested Response</th>
      <td>
        <span v-if="rec.requested_response" >Yes</span>
        <span v-if="!rec.requested_response" >No</span>
      </td>
    </tr>

    <tr>
      <th style="width: 180px;" class="text-left">Text</th>
      <td>
        <div style="white-space: pre-line">
          {{rec.text}}
        </div>
      </td>
    </tr>

  </table>

  <div class="text-h5">Log</div>
  <table v-if="rec.report" class="table table-striped table-sm full-width">
    <tr v-for="(crumb, idx) in rec.report.breadcrumbs.values" :key="idx">
      <th style="width: 80px;" class="text-left">{{crumb.level}}</th>
      <td style="white-space: pre-line">{{crumb.message}}</td>
    </tr>
  </table>





</div>


<div class="text-h5">Management Tools</div>

</div>

  <div class="row q-my-xl">
      <div class="col-12 q-px-sm">
        <show-data :data="rec"></show-data>
      </div>
  </div>


</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import adminJSErrorsModule from "src/modules/admin/store/module-admin-js-error";
import { adminMixin } from '../admin.mixin';
import storeRecViewMixin from 'src/mixins/store_rec_view.mixin';

export default {
  mixins: [
    adminMixin,
    storeRecViewMixin,
  ],
  data() {
    return {
      setField: null,
      setType: 'Str',
      setValue: null,
      vuexCollection: 'js_errors.recs',
      collection: 'js_errors',
      recId: this.$route.params.recId,

      vuexPath: 'admin/js_errors',
      vuexGetRecordAction: adminJSErrorsModule.actionTypes.GetRecordReques


    };
  },
  computed: {
    ...mapState('admin/js_errors', {
        loading: 'loading',
      }),
    ...mapGetters('admin/js_errors', [
      'getRecById',
    ]),
  },
  created() {
    this.fieldSet('reviewed', true);
  },
  methods: {

  }

}
</script>
