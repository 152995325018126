<template>
<div>
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="Admin" to="/admin/home" />
      <q-breadcrumbs-el label="Users" @click="reqData(true)">
        &nbsp;<q-icon color="red" name="refresh"></q-icon>
      </q-breadcrumbs-el>
    </q-breadcrumbs>

  <div class="row justify-between items-end q-my-sm">
    <div class="col full-height text-h6">

    </div>


  </div>

  <q-toolbar class="bg-blue-2" style="min-height: 40px;">
      <q-toolbar-title shrink class="q-pr-xl">
        Total Users
      {{numRecs}}
      <span v-if="numRecs != numTotalRecs">
        of {{numTotalRecs}}
      </span>
      </q-toolbar-title>


      <q-input v-model="qry.val" label="Filter Text" dense >
        <template v-slot:append>
          <q-icon v-if="qry.val !== null" name="close" class="cursor-pointer" @click="qry.val = null" />
          <q-icon name="filter_list" @click="gotoPage(1)" />
        </template>
      </q-input>

      <q-input v-model="qry.field" label="Filter Field" class="q-mx-sm" dense >
      </q-input>


     <q-space></q-space>

    <q-pagination
      :value="currentPage"
      :max="numPages"
      :max-pages="5"
      :ellipses="false"
      :boundary-links="true"
      :boundary-numbers="false"
      :direction-links="true"
      @input="gotoPage"
    >
    </q-pagination>

  </q-toolbar>

  <table class="table table-striped table-sm full-width">
    <tr v-for="user in recs" :key="user._id">

    <td class="q-pt-xs text-no-wrap" valign="top">
      {{ user.created_on | date  }}
    </td>
    <td class="q-pt-xs text-no-wrap" valign="top">
      {{ user.last_login | date  }}
    </td>
    <td>
        <router-link v-if="user._id" :to="{ name: 'adminUsersView', params: { recId: user._id }}" class="text-primary">
          <user-name :user="user" :show-email="true" /></router-link>
    </td>
    <td>
      {{user.email}}
    </td>
    <td>
       <q-chip v-for="(role, idx) in user.roles" :key="idx" size=".8em">{{role}}</q-chip>
    </td>
    <td class="text-right text-sm">
      <q-icon v-if="user.verified" name="check" color="green"></q-icon>
    </td>
    <td class="text-right text-sm">
      <q-icon v-if="user.active" name="check" color="green"></q-icon>
    </td>

    </tr>
  </table>

</div>
</template>

<script>
// import { mapState, mapGetters } from 'vuex';
import adminUserModule from "src/modules/admin/store/module-admin-user";
import storeRecListMixin from 'src/mixins/store_rec_list.mixin';

export default {
  mixins: [
    storeRecListMixin,
  ],
  data() {
    return {
      current: 1,
      // filter: {
      //   qry: null
      // },
      vuexPath: 'admin/users',
      collection: 'users',
      vuexCollection: 'users.recs',
      vuexPageRequestAction: adminUserModule.actionTypes.LoadRecordsPageRequest,

      // routeName: 'adminUsersList',
    }
  },

}
</script>
