<template>
<div>
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="Admin" to="/admin/home" />
      <q-breadcrumbs-el label="Users" to="/admin/users/list/1" />
      <q-breadcrumbs-el label="View">
        {{$route.params.recId}}
        <q-btn dense flat color="red" size="sm" icon="refresh" @click="refreshRec(true)" />
      </q-breadcrumbs-el>
    </q-breadcrumbs>

<div v-if="rec">
<div class="row q-my-sm">

</div>

<div class="text-h5">User Information</div>
<div class="striped q-mb-md">
  <div class="row">
    <div class="col-2 q-py-xs">Name</div>
    <div class="col-10 q-py-xs">{{rec.first_name}} {{rec.last_name}}</div>
  </div>

  <div class="row">
    <div class="col-2 q-py-xs">Email</div>
    <div class="col-10 q-py-xs">{{rec.email}}
      <a :href="'mailto:' + rec.email"><q-icon name="fal fa-envelope" /></a>
    </div>
  </div>

  <div class="row">
    <div class="col-2 q-py-xs">Roles</div>
    <div class="col-10">
      <q-chip v-for="role in rec.roles" :key="role" square dense removable text-color="white" color="blue" @remove="fieldPull('roles', role)">
        {{role}}
      </q-chip>
    </div>
  </div>

  <div class="row">
    <div class="col-2 q-py-xs">Created On</div>
    <div class="col-10 q-py-xs">{{rec.created_on | date}}</div>
  </div>

  <div class="row">
    <div class="col-2 q-py-xs">Last log in</div>
    <div class="col-10">{{rec.last_login | date('MMM D, YYYY hh:mma')}}</div>
  </div>

  <div class="row">
    <div class="col-2 q-py-xs">Verified</div>
    <div class="col-10"><q-chip square dense clickable @click="fieldSet('verified', !rec.verified)" >
      <q-avatar v-show="rec.verified" color="green" text-color="white" icon="check" />
      <q-avatar v-show="!rec.verified" color="red" text-color="white" icon="close" />
      Verified
    </q-chip></div>
  </div>

  <div class="row">
    <div class="col-2 q-py-xs">Active</div>
    <div class="col-10"><q-chip square dense clickable @click="fieldSet('active', !rec.active)" >
      <q-avatar v-show="rec.active" color="green" text-color="white" icon="check" />
      <q-avatar v-show="!rec.active" color="red" text-color="white" icon="close" />
      Active
    </q-chip></div>
  </div>
</div>

<div class="text-h5">User Organizations</div>
<div class="striped q-mb-md">
  <div v-for="org in rec.organizations" :key="org._id" class="row">
    <div class="col q-py-xs">
      {{org._id}}
    </div>
    <div class="col q-py-xs">
      <router-link :to="{name: 'adminOrgView', params: {recId: org._id}}">{{org.name}}</router-link>
    </div>
    <div class="col q-py-xs">
      {{org.relationship}}
    </div>
    <div class="col">
       <q-chip v-for="role in org.roles" :key="role" square dense removable text-color="white" color="grey">
        {{role}}
      </q-chip>
    </div>
  </div>
</div>

<q-card class="q-mb-md">
      <q-tabs
        v-model="postsTab"
        dense
        class="bg-grey-3 q-mt-md"
        active-color="blue-10"
        align="left"
        narrow-indicator
        >
        <q-tab name="posts" class="" no-caps >
          <div>
          Live Posts <q-btn dense flat color="red" size="sm" icon="refresh" @click="fetchPosts" />
          </div>
        </q-tab>

        <q-tab name="pending_posts" class="" no-caps >
          <div>
          Pending Posts <q-btn dense flat color="red" size="sm" icon="refresh" @click="fetchPendingPosts" />
          </div>
        </q-tab>

        <q-tab name="expired_posts" class="" no-caps >
          <div>
          Expired Posts <q-btn dense flat color="red" size="sm" icon="refresh" @click="fetchExpiredPosts" />
          </div>
        </q-tab>

        <q-tab name="orders" class="" no-caps >
          <div>
          Orders <q-btn dense flat color="red" size="sm" icon="refresh" @click="fetchOrders" />
          </div>
        </q-tab>

        <q-tab name="transactions" class="" no-caps >
          <div>
          Transactions <q-btn dense flat color="red" size="sm" icon="refresh" @click="fetchTransactions" />
          </div>
        </q-tab>

      </q-tabs>

      <q-separator />

      <q-tab-panels v-model="postsTab" animated>

        <q-tab-panel name="posts">
          <div class="striped">
            <div v-for="post in userPosts" :key="post._id" class="row flex">
              <div class="col col-shrink q-py-xs" style="min-width: 100px;">
                {{post.posted_on | date}}
              </div>
              <div class="col q-py-xs">
                <router-link :to="{name: 'adminPostView', params: {recId: post._id}}">{{post.headline}}</router-link>
              </div>
              <div class="col col-auto q-py-xs">
                {{post._id}}
              </div>
            </div>
          </div>

        </q-tab-panel>

        <q-tab-panel name="pending_posts">
        </q-tab-panel>

        <q-tab-panel name="expired_posts">
        </q-tab-panel>

        <q-tab-panel name="transactions">
        </q-tab-panel>

        <q-tab-panel name="orders">
          <div class="striped text-xs">
            <div v-for="order in userOrders" :key="order._id" class="row flex">
              <div class="col col-shrink q-py-xs" style="min-width: 100px;">
                {{order.created_on | date}}
              </div>
              <div class="col q-pa-xs">
                <router-link :to="{name: 'adminOrdersView', params: {recId: order._id}}">
                {{order._id}}
                </router-link>
              </div>

              <div class="col q-pa-xs text-xs">
                ({{order.discount | currency }})
              </div>
              <div class="col q-pa-xs">
                <span v-if="order.coupon" class="text-xs">{{order.coupon.code}}</span>
              </div>
              <div class="col q-pa-xs">
                {{order.total | currency }}
              </div>
              <div class="col q-pa-xs">
                <q-icon v-if="order.paid" name="fal fa-credit-card" color="green"></q-icon>
              </div>

            </div>
          </div>

        </q-tab-panel>


      </q-tab-panels>
</q-card>


<div v-if="rec">
<div class="text-h5">Management Tools</div>


<div class="q-my-lg q-gutter-sm">
  <q-btn color="blue" size="sm" icon="person" @click="becomeUser">Become User</q-btn>
</div>

<div class="row">
  <div class="col-auto justify-start">
    <q-input
        v-model="newPass"
        label="New Password"
        borderless dense
        :rules="[ val => val && val.length > 0 || 'Please type something']"
      >
      <template v-slot:after>
        <q-btn class="q-ma-md" size="sm" label="Set Password" color="secondary" @click="setPassword()"></q-btn>
      </template>
    </q-input>
  </div>
</div>

<div class="row">
  <div class="col-auto justify-start">
    <q-input
        :value="rec.email"
        label="Email"
        borderless=""
        dense
        :rules="[ val => val && val.length > 0 || 'Please type something']"
        @change="updateValue('newEmail', $event)"
      >
      <template v-slot:after>
        <q-btn class="q-ma-md" size="sm" label="Set Email" color="secondary" @click="fieldSet('email', newEmail)"></q-btn>
      </template>
    </q-input>
  </div>
</div>

<div class="row">
  <div class="col-auto justify-start">
    <q-input
        v-model="newRole"
        label="New Role"
        borderless=""
        dense
        :rules="[ val => val && val.length > 0 || 'Please type something']"
      >
      <template v-slot:after>
        <q-btn class="q-ma-md" size="sm" label="Add Role" color="secondary" @click="fieldAddToSet('roles', newRole)"></q-btn>
      </template>
    </q-input>
  </div>
</div>

<div class="row">
  <div class="col-auto justify-start">
    <del-rec
      :rec-id="rec._id"
      :action="delAction"
      :btn-props="{color: 'negative', size: 'sm'}"
      @removed="recordRemoved($event)"
      ></del-rec>
  </div>
</div>

<q-toolbar class="bg-grey-3 text-white q-my-md shadow-2 row q-col-gutter-xs">
  <div class="col-3">
    <q-select v-model="setField" :options="recKeys.sort()" dense borderless class="" @input="setValue = getPathValue($event)" />
  </div>

  <div v-if="setType === 'Bool'" class="col-2">
    <q-toggle v-if="setType === 'Bool'" v-model="setValue" dense />
  </div>

  <div v-if="setType !== 'Bool'" class="col-3">
     <q-input
        v-if="setType !== 'Bool'"
        v-model="setValue"
        borderless
        dense
        class="q-pa-xs"
        :rules="[ val => val && val.length > 0 || 'Please type something']"
      >
    </q-input>
  </div>


  <div class="col-1">
    <q-select v-model="setType" :options="['Str', 'Bool', 'Number', 'JSON']" dense borderless />
  </div>

  <div class="col-2">
    <q-btn class="q-ma-md" size="sm" label="Set Value" color="secondary" @click="fieldSet(setField, setValue)"></q-btn>
  </div>


</q-toolbar>

</div>

</div>
<div v-else>
  No User
</div>

  <div class="row q-my-xl">
      <div class="col-12 q-px-sm">
        <show-data :data="rec || {}"></show-data>
      </div>
  </div>


</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
// import { AdminUsersActionTypes } from "../../../store/module-admin/module-admin-user/action-types";
// import { AdminPostsActionTypes } from "../../../store/module-admin/module-admin-post/action-types";
import { adminMixin } from '../admin.mixin';
import storeRecViewMixin from 'src/mixins/store_rec_view.mixin';
import { get as loadashGet } from 'lodash';
import * as DotObject from 'dot-object';
import adminUsersModule from "src/modules/admin/store/module-admin-user";
import adminPostsModule from "src/modules/admin/store/module-admin-post";
import adminOrdersModule from "src/modules/admin/store/module-admin-order";
import adminTransactionsModule from "src/modules/admin/store/module-admin-transaction";

export default {
  mixins: [
    adminMixin,
    storeRecViewMixin,
  ],
  data() {
    return {
      postsTab: 'posts',

      newPass: null,
      newEmail: null,
      newRole: null,
      setField: null,
      setType: 'Str',
      setValue: null,

      delAction: 'admin/users/' + adminUsersModule.actionTypes.DelRecordRequest,
      vuexCollection: 'users.recs',
      collection: 'users',

      vuexPath: 'admin/users',
      vuexGetRecordAction: adminUsersModule.actionTypes.GetRecordRequest,
      // recId: this.$route.params.userId,
    };
  },
  computed: {
    ...mapState('admin/users', {
        loading: 'loading',
        loaded: 'loaded',
      }),
    ...mapGetters('admin/users', [
      'getRecById',
    ]),
    userPosts() {
      return  this.$store.getters['admin/posts/getRecsByUserId'](this.$route.params.recId);
    },
    userOrders() {
      return  this.$store.getters['admin/orders/getRecsByUserId'](this.$route.params.recId);
    },
    recKeys() {
      return this.rec ? Object.keys( DotObject.dot(this.rec) ) : [];
    },
  },
  created() {
    console.log('created');
  },
  methods: {
    setPassword() {
      console.log('setting password', this.newPass);
      this.$store.dispatch(`admin/users/${adminUsersModule.actionTypes.AdminUsersSetPassRequest}`, { user_id: this.rec._id, password: this.newPass });
    },
    fetchPosts() {
      this.$store.dispatch(`admin/posts/${adminPostsModule.actionTypes.LoadRecordsPageRequest}`, {
          qry: {
            field: 'user._id',
            val: this.rec._id,
          },
          page_id: 1,
         });
    },
    fetchPendingPosts() {
      // this.$store.dispatch(`admin/posts/${adminPostsModule.actionTypes.LoadRecordsPageRequest}`, {
      //     qry: {
      //       field: 'user._id',
      //       val: this.rec._id,
      //     },
      //     page_id: 1,
      //    });
    },
    fetchExpiredPosts() {
      // this.$store.dispatch(`admin/posts/${adminPostsModule.actionTypes.LoadRecordsPageRequest}`, {
      //     qry: {
      //       field: 'user._id',
      //       val: this.rec._id,
      //     },
      //     page_id: 1,
      //    });
    },
    fetchOrders() {
      // this.becomingUser = true;
      this.$store.dispatch(`admin/orders/${adminOrdersModule.actionTypes.LoadRecordsPageRequest}`, {
          qry: {
            field: 'user._id',
            val: this.rec._id,
          },
          page_id: 1,
         });
    },
    fetchTransactions() {
      // this.becomingUser = true;
      this.$store.dispatch(`admin/transactions/${adminTransactionsModule.actionTypes.LoadRecordsPageRequest}`, {
          qry: {
            field: 'user._id',
            val: this.rec._id,
          },
          page_id: 1,
         });
    },
    becomeUser() {
      // this.becomingUser = true;
      this.$store.dispatch(`admin/users/${adminUsersModule.actionTypes.AdminBecomeUserRequest}`, { userId: this.rec._id });
    },
    updateValue(v, evt) {
      this[v] = evt.target.value;
    },
    getPathValue(path) {
      const val = loadashGet(this.rec, path);
      console.log('getPathValue', path, val);
      if (val === true || val === false) {
        this.setType = 'Bool';
      } else {
        this.setType = 'Str';
      }

      return val;
    }

  }

}
</script>
