<template>
  <div>
     <q-toolbar class="bg-grey-4" style="min-height: 40px;">
      <q-toolbar-title shrink class="q-pr-xl text-h6">
        Posts
      </q-toolbar-title>

      <q-btn :to="{name: 'adminPostsHome'}" size="md" no-caps flat >
        Home
      </q-btn>

      <q-btn  :to="{name: 'adminPostsList', params: { pageId: 1 }}"  size="md" no-caps flat >
        Live
      </q-btn>

      <q-btn  :to="{name: 'adminPostsPendingList', params: { pageId: 1 }}"  size="md" no-caps flat >
        Pending
      </q-btn>

      <q-btn  :to="{name: 'adminPostsHome', params: { pageId: 1 }}"  size="md" no-caps flat >
        Expired
      </q-btn>

      <q-btn  :to="{name: 'adminJobApplicationsList', params: { pageId: 1 }}"  size="md" no-caps flat >
        Applications
      </q-btn>

      <q-space></q-space>

    </q-toolbar>

    <q-linear-progress v-if="loading" color="secondary" indeterminate />

    <div class="q-pa-md">
    <router-view />
    </div>
  </div>
</template>
<style scoped>
.v-progress-linear {
  margin-top: 0;
}
</style>
<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('admin/posts', {
      loading: 'loading',
    }),
  },
}
</script>

