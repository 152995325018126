<template>
<div>
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="Admin" to="/admin/home" />
      <q-breadcrumbs-el label="Jobs" to="/admin/jobs/list/1" />
      <q-breadcrumbs-el label="Applications" @click="reqData(true)">
        <q-icon color="red" name="refresh"></q-icon>
      </q-breadcrumbs-el>
    </q-breadcrumbs>

  <div class="row justify-between items-end q-my-sm">
    <div class="col full-height q-gutter-md">



    </div>
  </div>

  <q-toolbar class="bg-blue-2" style="min-height: 40px;">
      <q-toolbar-title shrink class="q-pr-xl">
        Total Job Application Recs
      {{numRecs}}
      <span v-if="numRecs != numTotalRecs">
        of {{numTotalRecs}}
      </span>
      </q-toolbar-title>

      <q-input v-model="qry.val" label="Filter Text" dense >
        <template v-slot:append>
          <q-icon v-if="qry.val !== null" name="close" class="cursor-pointer" @click="qry.val = null" />
          <q-icon name="filter_list" @click="gotoPage(1)" />
        </template>
      </q-input>

      <q-input v-model="qry.field" label="Filter Field" class="q-mx-sm" dense >
      </q-input>

     <q-space></q-space>

    <q-pagination
      :value="currentPage"
      :max="numPages"
      :max-pages="5"
      :ellipses="false"
      :boundary-links="true"
      :boundary-numbers="false"
      :direction-links="true"
      @input="gotoPage"
    >
    </q-pagination>

  </q-toolbar>

  <table class="table table-striped table-sm full-width">
    <tr v-for="app in recs" :key="app._id" @mouseenter="expandId = app._id">

      <td class="q-pt-xs text-no-wrap" valign="top">
        <q-checkbox v-model="ids" :val="app._id" dense color="green" />
      </td>

      <td class="q-pt-xs text-no-wrap" valign="top">
        {{app.created_on | date('YYYY-MM-DD HH:mm')}}
      </td>

      <td class="q-pt-xs text-no-wrap" valign="top">
        <div class="text-weight-bold"><router-link :to="{ name: 'adminPostView', params: {postId: app.post._id}}">{{ app.post.headline }}</router-link></div>
        <div class="text-subtitle">{{ app.post.position }}</div>
        {{ app.post.location.city }},
        {{ app.post.location.state_code }}<br>
        &lt;{{app.apply_email}}&gt;
      </td>

      <td class="q-pt-xs text-no-wrap" valign="top">
        <q-icon title="Import" name="fas fa-upload" color="red" ></q-icon>
      </td>


    <td class="q-pt-xs text-no-wrap" valign="top">
    </td>

    <td>
        <router-link v-if="app._id" :to="{ name: 'adminJobApplicationsView', params: { recId: app._id }}" class="text-primary text-bold">{{app.subject}}</router-link>
        <div class="text-caption">
          {{app.user.name}}
        </div>
        <div class="text-caption">
          &lt;{{app.user.email}}&gt;
        </div>
    </td>

    <td class="text-right text-sm">
    </td>

    <td class="text-right text-sm">
      <q-icon v-if="false" title="reviewed" name="fas fa-book-alt" color="red" @click="fieldSet('reviewed', !app.reviewed, app._id)"></q-icon>
    </td>

    <td class="text-center text-sm">
      <q-icon title="resend" name="fas fa-redo-alt" color="red" @click="resend(app._id)"></q-icon>
    </td>

    </tr>
  </table>

</div>
</template>

<style scoped>
tr:hover {
  background-color: #BBDEFB;
}
</style>

<script>
import { mapState } from 'vuex';
import { debounce } from "lodash";
import { adminMixin } from '../../admin.mixin';

import adminPostAppsModule from "src/modules/admin/store/module-admin-post/module-admin-job-applications";
import storeRecListMixin from 'src/mixins/store_rec_list.mixin';

export default {
  mixins: [
    adminMixin,
    storeRecListMixin
  ],
  data() {
    return {
      collection: 'job_applies',
      vuexCollection: 'posts.applications.recs',
      vuexPath: 'admin/posts/applications',
      vuexPageRequestAction: adminPostAppsModule.actionTypes.LoadRecordsPageRequest,

      current: 1,
      expandId: null,
      ids: [],
      filter: {
        qry: null
      },
      spec: {
        direct: true
      },
    }
  },

  computed: {
      ...mapState('admin/posts/applications', {
        loading: 'loading',
        loaded: 'loaded',
      }),
  },

  watch: {
  },

  created () {
  },

  methods: {

    selectAll() {
      this.ids = this.apps.map( x => x._id );
    },
    resend(appId) {
      this.$store.dispatch( 'admin/posts/applications/' + AdminJobApplicationsActionTypes.AdminJobApplicationsResendApp, { appId } );
    },
    // importJob(jobId) {
    //   this.$store.dispatch( 'admin/posts/applications/' + AdminJobApplicationsActionTypes.AdminOnboardingJobRecordsImportRequest, { ids: [ jobId ]} );
    // },
    // doImport() {
    //   this.$store.dispatch( 'admin/posts/applications/' + AdminJobApplicationsActionTypes.AdminOnboardingJobRecordsImportRequest, { ids: this.ids } );
    // },
    // markReviewed() {
    //   this.$store.dispatch( 'admin/posts/applications/' + AdminJobApplicationsActionTypes.AdminOnboardingSetJobRecordsReviewedRequest, { ids: this.ids } );
    // },
    },
}
</script>
