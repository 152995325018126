<template>
<div>
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="Admin" to="/admin/home" />
      <q-breadcrumbs-el label="Orders" @click="reqData(true)">
        <q-icon color="red" name="refresh"></q-icon>
      </q-breadcrumbs-el>
    </q-breadcrumbs>

  <div class="row justify-between items-end q-my-sm">
    <div class="col full-height text-h6">

    </div>


  </div>

  <q-toolbar class="bg-blue-2" style="min-height: 40px;">
      <q-toolbar-title shrink class="q-pr-xl">
        Total Orders
      {{numRecs}}
      <span v-if="numRecs != numTotalRecs">
        of {{numTotalRecs}}
      </span>
      </q-toolbar-title>

      <q-input v-model="qry.val" label="Filter Text" dense >
        <template v-slot:append>
          <q-icon v-if="qry.val !== null" name="close" class="cursor-pointer" @click="qry.val = null" />
          <q-icon name="filter_list" @click="gotoPage(1)" />
        </template>
      </q-input>

      <q-input v-model="qry.field" label="Filter Field" class="q-mx-sm" dense >
      </q-input>

     <q-space></q-space>

    <q-pagination
      :value="currentPage"
      :max="numPages"
      :max-pages="5"
      :ellipses="false"
      :boundary-links="true"
      :boundary-numbers="false"
      :direction-links="true"
      @input="gotoPage"
    >
    </q-pagination>

  </q-toolbar>

          <table class="table table-striped table-sm full-width">
            <tr v-for="rec in recs" :key="rec._id">

              <td>
                <router-link v-if="rec._id" :to="{ name: 'adminOrdersView', params: { recId: rec._id }}" class="text-primary">
                  <q-icon size="sm" name="description"></q-icon>
                </router-link>
              </td>

              <td>
                <div>{{rec.created_on | date}}</div>
                <span class="text-xs">{{ rec.created_on | date("hh:mma")}}</span>
              </td>

              <td>
              <div class="text-sm">
                <router-link v-if="rec.post" :to="{ name: rec.paid ? 'adminPostView' : 'adminPostsPendingView', params: { recId: rec.post._id }}" class="text-primary">{{rec.post.headline}}</router-link>
                <span v-else>No Post</span>
              </div>
              <div v-if="rec.organization" class="text-xs">
                <router-link :to="{ name: 'adminOrgView', params: { recId: rec.organization._id }}" class="text-primary">{{rec.organization.name}}</router-link>
              </div>
            </td>

            <td>
              <user-name :user="rec.user" :show-id="true" :show-email="true" />
            </td>
            <td>
              <div>{{rec.discount | currency }}</div>
              <span v-if="rec.coupon" class="text-xs">{{rec.coupon.code}}</span>
            </td>
            <td>
              {{rec.total | currency }}
            </td>
            <td class="text-right text-sm">
              <q-icon v-if="rec.paid" name="fal fa-credit-card" color="green"></q-icon>
            </td>

          </tr>
        </table>

</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { adminMixin } from '../admin.mixin';
import { debounce } from "lodash";
import storeRecListMixin from 'src/mixins/store_rec_list.mixin';
import adminOrdersModule from "src/modules/admin/store/module-admin-order";

export default {
  mixins: [
    adminMixin,
    storeRecListMixin,
  ],
  data() {
    return {
      collection: 'orders',
      vuexCollection: 'orders.recs',

      vuexPath: 'admin/orders',
      vuexPageRequestAction: adminOrdersModule.actionTypes.LoadRecordsPageRequest,
    }
  },

  // computed: {
  //     ...mapState('admin/orders', {
  //       loading: 'loading',
  //     }),
  //     currentPage() {
  //       return +this.$route.params.pageId;
  //     },
  //     numOrders() {
  //       return this.$store.getters['admin/orders/totalOrderRecords'];
  //     },
  //     numFilteredOrders() {
  //       return this.$store.getters['admin/orders/totalFilteredOrderRecords'];
  //     },
  //     numPages() {
  //       return Math.ceil(this.numFilteredOrders / 25);
  //     },
  //     orders() {
  //       return this.$store.getters['admin/orders/getFilteredOrderPageSet'](this.currentPage);
  //     },
  // },

  // watch: {
  //   // call again the method if the route changes
  //   '$route': function() { this.reqData(); },
  //   'filter.qry': {
  //     handler: debounce( function(newValue) {
  //         console.log('DEBOUNCE', newValue);
  //         this.$store.dispatch( 'admin/orders/' + AdminOrdersActionTypes.AdminOrdersSetFilterQry, newValue);
  //       }, 200),
  //   },
  // },

  // created () {
  //   this.reqData();
  // },

  // methods: {
  //   gotoPage (pageId) {
  //     console.log('gotoPage', pageId);
  //     this.$router.push({ name: 'adminOrdersList', params: { pageId }});
  //   },
  //   reqData (force) {
  //     console.log('REQUESTING DATA...', this.$route.params.pageId);
  //     if (! this.loaded || force) {
  //       this.$store.dispatch( 'admin/orders/' + AdminOrdersActionTypes.AdminOrdersLoadRecordsRequest );
  //     }
  //     console.log('REQUESTING DATA DONE...');
  //   },
  // },
}
</script>
