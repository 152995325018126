<template>
<div>
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="Admin" to="/admin/home" />
      <q-breadcrumbs-el label="Orders" to="/admin/orders/list/1" />
      <q-breadcrumbs-el :label="$route.params.recId" />
      <q-breadcrumbs-el label="View">
        <q-btn dense flat color="red" size="sm" icon="refresh" @click="refreshRec(true)" />
      </q-breadcrumbs-el>
    </q-breadcrumbs>

<div v-if="rec">
<div class="row q-my-sm" >
  <div class="col-12 text-h5">
    Order {{rec._id}}
    <q-icon name="refresh" class="q-mx-md" color="red" @click="refreshRec()"></q-icon>
  </div>

  <div class="col-12">

  </div>
</div>

<div class="row q-my-xl">
  <table class="table table-striped table-sm full-width">
    <tr>
      <th style="width: 180px;" class="text-left">User</th>
      <td>
        <user-name :user="rec.user" :show-id="true" :show-email="true" />
      </td>
    </tr>

    <tr>
      <th style="width: 180px;" class="text-left"><span v-if="pending">Pending</span> Post</th>
      <td>
        <span v-if="rec.post">
          <router-link :to="{name: pending ? 'adminPostsPendingView' : 'adminPostView', params: { recId: rec.post._id} }">[ {{rec.post._id}} ]</router-link> &nbsp;
          {{ rec.post.headline }}
        </span>


         <q-chip square dense clickable @click="pending = !pending" >
          <q-avatar v-show="! pending" color="green" text-color="white" icon="check" />
          <q-avatar v-show="pending" color="red" text-color="white" icon="close" />
          Link&nbsp;
          <span v-show="pending">Pending</span>&nbsp;
          Post
        </q-chip>

      </td>
    </tr>

    <tr>
      <th style="width: 180px;" class="text-left">Organization</th>
      <td>
        <span v-if="rec.organization">
          <router-link :to="{name: 'adminOrgView', params: { recId: rec.organization._id} }">[ {{rec.organization._id}} ]</router-link> &nbsp;
          <router-link :to="{name: 'adminOrgView', params: { recId: rec.organization._id} }">{{rec.organization.name}}</router-link>
        </span>
      </td>
    </tr>

    <tr>
      <th style="width: 180px;" class="text-left">Created On</th>
      <td>
       {{rec.created_on | date('MMM D, YYYY hh:mma')}}
      </td>
    </tr>

    <tr>
      <th style="width: 180px;" class="text-left">Paid</th>
      <td>
        <q-chip square dense clickable @click="markPaid(!rec.paid)" >
          <q-avatar v-show="rec.paid" color="green" text-color="white" icon="check" />
          <q-avatar v-show="!rec.paid" color="red" text-color="white" icon="close" />
          <span v-show="!rec.paid">Not</span>&nbsp;
          Paid
        </q-chip>
      </td>
    </tr>

    <tr>
      <th style="width: 180px;" class="text-left">Paid On</th>
      <td>
       {{rec.paid_on | date('MMM D, YYYY hh:mma')}}
      </td>
    </tr>

    <tr>
      <th style="width: 180px;" class="text-left">Subtotal</th>
      <td>
       {{rec.subtotal | currency }}
      </td>
    </tr>

    <tr>
      <th style="width: 180px;" class="text-left">Discount</th>
      <td>
       {{rec.discount | currency }}

       <span v-if="rec.coupon">
         [ <router-link :to="{name: 'adminCouponView', params: { couponId: rec.coupon._id}}">{{rec.coupon.code}}</router-link> - {{rec.coupon.amount}} {{rec.coupon.amount_type}} ]
       </span>

      </td>
    </tr>

    <tr>
      <th style="width: 180px;" class="text-left">Total </th>
      <td>
       {{rec.total | currency }}
      </td>
    </tr>

    <tr>
      <th style="width: 180px;" class="text-left">Transaction ID</th>
      <td>
       {{rec.transaction_id }}
      </td>
    </tr>

    <tr>
      <th style="width: 180px;" class="text-left">Auth TXN Id</th>
      <td>
       {{rec.trans_id }}
      </td>
    </tr>

  </table>
</div>


<div class="text-h5">Management Tools</div>

<q-toolbar class="bg-grey-3 text-white q-my-md shadow-2 row q-col-gutter-xs">
  <div class="col-3">
    <q-select v-model="setField" :options="recKeys.sort()" dense borderless class="" @input="setValue = getPathValue($event)" />
  </div>

  <div v-if="setType === 'Bool'" class="col-2">
    <q-toggle v-if="setType === 'Bool'" v-model="setValue" dense />
  </div>

  <div v-if="setType !== 'Bool'" class="col-3">
     <q-input
        v-if="setType !== 'Bool'"
        v-model="setValue"
        borderless
        dense
        class="q-pa-xs"
        :rules="[ val => val && val.length > 0 || 'Please type something']"
      >
    </q-input>
  </div>


  <div class="col-1">
    <q-select v-model="setType" :options="['Str', 'Bool', 'Number', 'JSON']" dense borderless />
  </div>

  <div class="col-2">
    <q-btn class="q-ma-md" size="sm" label="Set Value" color="secondary" @click="fieldSet(setField, setValue)"></q-btn>
  </div>


</q-toolbar>

</div>


  <div class="row q-my-xl">
      <div class="col-12 q-px-sm">
        <show-data :data="rec"></show-data>
      </div>
  </div>


</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { get as loadashGet } from 'lodash';
import * as DotObject from 'dot-object';
import adminMixin from '../admin.mixin';
import adminOrdersModule from "src/modules/admin/store/module-admin-order";
import storeRecViewMixin from 'src/mixins/store_rec_view.mixin';

export default {
  mixins: [
    adminMixin,
    storeRecViewMixin,
  ],
  data() {
    return {
      collection: 'orders',
      vuexCollection: 'orders.recs',
      vuexPath: 'admin/orders',
      vuexGetRecordAction: adminOrdersModule.actionTypes.GetRecordRequest,
      recId: this.$route.params.recId,

      pending: true,
      setField: null,
      setType: 'Str',
      setValue: null,
    };
  },
  computed: {
    ...mapState('admin/orders', {
        loading: 'loading',
      }),
    ...mapGetters('admin/orders', [
      'getRecById',
    ]),
    // order() {
    //   return  this.$store.getters['admin/orders/getOrderById'](this.$route.params.recId);
    // },
    // post() {
    //   return  this.$store.getters['admin/orders/getPostByOrderId'](this.$route.params.recId);
    // },
    recKeys() {
      return this.rec ? Object.keys( DotObject.dot(this.rec) ) : [];
    },
  },
  created() {
    //  if (! this.rec ) {
    //    this.refreshRec();
    // }
  },
  methods: {
    // refreshRec() {
    //   this.$store.dispatch(`admin/orders/${AdminOrdersActionTypes.AdminOrdersGetRecordRequest}`, { orderId: this.$route.params.orderId });
    // },
    updateValue(v, evt) {
      this[v] = evt.target.value;
    },
    markPaid(paid) {
      this.fieldSet('paid', paid);
      if (this.rec.post && this.rec.post._id) {
        this.fieldSet('order_info.paid', paid, this.rec.post._id,
          this.pending ? 'posts_prep' : 'posts',  this.pending ? 'pending_posts.posts' : 'posts',
          this.pending ? 'pending_posts.posts' : 'posts' );
      }
    },
    getPathValue(path) {
      const val = loadashGet(this.rec, path);
      console.log('getPathValue', path, val);
      if (val === true || val === false) {
        this.setType = 'Bool';
      } else {
        this.setType = 'Str';
      }

      return val;
    }

  }

}
</script>
