<template>
<div>
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="Admin" to="/admin/home" />
      <q-breadcrumbs-el label="Coupons" @click="reqData(true)">
        &nbsp;<q-icon color="red" name="refresh"></q-icon>
      </q-breadcrumbs-el>
    </q-breadcrumbs>

  <q-toolbar class="bg-blue-2" style="min-height: 40px;">
      <q-toolbar-title shrink class="q-pr-xl">
        Total Coupons
        {{numRecs}}
      <span v-if="numRecs != numTotalRecs">
        of {{numTotalRecs}}
      </span>
      </q-toolbar-title>

      <q-input v-model="qry.val" label="Filter Text" dense >
        <template v-slot:append>
          <q-icon v-if="qry.val !== null" name="close" class="cursor-pointer" @click="qry.val = null" />
          <q-icon name="filter_list" @click="gotoPage(1)" />
        </template>
      </q-input>

      <q-input v-model="qry.field" label="Filter Field" class="q-mx-sm" dense >
      </q-input>

     <q-space></q-space>

    <q-pagination
      :value="currentPage"
      :max="numPages"
      :max-pages="5"
      :ellipses="false"
      :boundary-links="true"
      :boundary-numbers="false"
      :direction-links="true"
      @input="gotoPage"
    >
    </q-pagination>

  </q-toolbar>

  <table class="table table-striped table-sm full-width">
    <tr>
      <th class="text-left">Created</th>
      <th class="text-left">Name</th>
      <th class="text-left">Code</th>
      <th class="text-right">Discount</th>
      <th class="text-left">Expires</th>
      <th class="text-right">Limit</th>
      <th class="text-right"># Used</th>
      <th></th>
    </tr>
    <tr v-for="coupon in recs" :key="coupon._id">

    <td class="q-pt-xs text-no-wrap" valign="top">
      {{ coupon.created_on | date  }}
    </td>
    <td>
        <router-link v-if="coupon._id" :to="{ name: 'adminCouponView', params: { recId: coupon._id }}" class="text-primary">{{coupon.name}}</router-link>
    </td>
    <td>
        <router-link v-if="coupon._id" :to="{ name: 'adminCouponView', params: { recId: coupon._id }}" class="text-primary">{{coupon.code}}</router-link>
    </td>
    <td class="text-right text-sm"> <span v-if="coupon.amount_type === 'dollars'">$</span>{{coupon.amount }}<span v-if="coupon.amount_type === 'percent'">%</span> </td>
    <td class="text-sm"> {{coupon.expires_on | date }} </td>
    <td class="text-right text-sm"> {{coupon.use_limit}} </td>
    <td class="text-right text-sm"> {{coupon.use_count}} </td>
    <td class="text-right text-sm">
      <q-icon v-if="coupon.active" name="check" color="green" @click="fieldSet('active', !coupon.active, coupon._id)"></q-icon>
      <q-icon v-if="! coupon.active" name="close" @click="fieldSet('active', !coupon.active, coupon._id)"></q-icon>
    </td>

    </tr>
  </table>


  <q-btn :to="{name: 'adminCouponsAdd'}" color="secondary" size="sm" icon-right="add">Add Coupon</q-btn>

</div>
</template>

<script>
import { mapState } from 'vuex';
import { date } from 'quasar'
import { adminMixin } from '../admin.mixin';

import adminCouponsModule from "src/modules/admin/store/module-admin-coupon";
import storeRecListMixin from 'src/mixins/store_rec_list.mixin';

export default {
  mixins: [
    adminMixin,
    storeRecListMixin,
  ],
  data() {
    return {
      collection: 'coupons',
      vuexCollection: 'coupons.recs',
      vuexPath: 'admin/coupons',
      vuexPageRequestAction: adminCouponsModule.actionTypes.LoadRecordsPageRequest,

      current: 1,
    }
  },
  computed: {
      ...mapState('admin/coupons', {
        loading: 'loading',
        loaded: 'loaded',
      }),
  },

  watch: {
  },

  created () {
  },

  methods: {
  },
}
</script>
