<template>
<q-toolbar class="bg-blue-2" style="min-height: 40px;">
      <q-toolbar-title shrink class="q-pr-xl">
        Change Post User
      </q-toolbar-title>

      <q-select
        v-model="changeUser"
        use-input
        fill-input
        clearable
        input-debounce="500"
        :options="userList"
        dense
        @filter="userFilter"
      >
        <template v-slot:selected>
          Company:
          <q-chip
            v-if="changeUser"
            dense
            square
            color="white"
            text-color="primary"
            class="q-my-none q-ml-xs q-mr-none"
          >
            <q-avatar color="primary" text-color="white" icon="user" />
            {{ changeUser.first_name }}
            {{ changeUser.last_name }}
            {{ changeUser.email }}
          </q-chip>
          <q-badge v-else>*none*</q-badge>
        </template>
        <template v-slot:option="scope">
          <q-item
            v-bind="scope.itemProps"
            v-on="scope.itemEvents"
            >
            <q-item-section>
              {{scope.opt._id}}
              {{scope.opt.first_name}}
              {{scope.opt.last_name}}
              &lt;{{scope.opt.email}}&gt;
            </q-item-section>
          </q-item>
        </template>
        <template v-slot:selected-item="scope">
          <div
            v-if="scope.opt"
          >
            {{ scope.opt._id }}
            {{scope.opt.first_name}}
            {{scope.opt.last_name}}
            &lt;{{scope.opt.email}}&gt;
          </div>
          <div v-else>*none*</div>
        </template>
        <template v-slot:no-option>
          <q-item>
            <q-item-section class="text-grey">
              No results
            </q-item-section>
          </q-item>
        </template>
      </q-select>

      <q-btn color="secondary" size="sm" flat icon="refresh" no-caps @click="loadUsers" />
      <q-btn color="secondary" size="sm" no-caps @click="setUser" >
        Set User
      </q-btn>
  </q-toolbar>
</template>
<style scoped>
</style>
<script>
import adminUserModule from "src/modules/admin/store/module-admin-user";

export default {
  name: "AdminChooseUser",
  props: {
    users: {
      type: Object,
      default: null,
      required: true,
    },
    searchFields: {
      type: Array,
      default: () => ['_id', 'name'],
      required: false,
    },
  },
  data() {
    return {
      changeUser: null,
      userList: Object.values(this.users),
    };
  },
  methods: {
    userFilter (val, update) {
      this.userList = Object.values(this.users);

      console.log('UF users', this.users);
      console.log('UF ', this.userList);

      update(() => {
        const terms = val.toLowerCase().split(' ');
        this.userList = Object.values(this.users).filter(u => {
          for (var i = 0; i < terms.length; i++) {
            var matched = false;

            for (var sfidx = 0; sfidx < this.searchFields.length; sfidx++) {
                if ( u[ this.searchFields[sfidx] ] &&
                     u[ this.searchFields[sfidx] ].toLowerCase().indexOf(terms[i]) > -1
                ) {
                    matched = true;
                }
            }

            if (! matched) {
              return false;
            }

          }

          return true;
        });
      })
    },
    setUser() {
        this.$emit("update", this.changeUser );
    },
    loadUsers() {
      this.$store.dispatch( 'admin/users/' + adminUserModule.actionTypes.AdminUsersLoadMetaRequest );
    }
  },
};
</script>

