/* eslint-disable no-unused-vars */
import { Bar, mixins } from 'vue-chartjs'
import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";
const { reactiveProp } = mixins;

export default {
  name: 'BarChart',
  extends: Bar,
  mixins: [reactiveProp],
  props: ['options'],
  mounted () {
    this.renderChart(this.chartData, this.options);
  }
}
