
import { AdminActionTypes } from 'src/modules/admin/store/action-types';

export const adminMixin = {
    data() {
      return {
          collection: null,
          vuexCollection: null,
          recId: 'OVER WRITTEN!',
          setMeta: false,
      };
    },
    methods: {
        async fieldOn(key, id, collection) {
            if (! collection) {
                collection = this.collection;
            }
            if (! id) {
                id = this.recId;
            }
            this.$store.dispatch(`admin/${AdminActionTypes.AdminFieldOnRequest}`, { collection, key, id});
        },

        async fieldOff(key, id, collection) {
            if (! collection) {
                collection = this.collection;
            }
            if (! id) {
                id = this.recId;
            }
            this.$store.dispatch(`admin/${AdminActionTypes.AdminFieldOffRequest}`, { collection, key, id});
        },

        async fieldSet(key, value, id, collection, vuexCollection, setMeta) {
            if (! collection) {
                collection = this.collection;
            }
            if (! vuexCollection) {
                vuexCollection = this.vuexCollection || collection;
            }
            if (! setMeta) {
                setMeta = this.setMeta;
            }
            if (! id) {
                id = this.recId;
            }
            this.$store.dispatch(`admin/${AdminActionTypes.AdminFieldSetRequest}`, { collection, key, id, value, vuexCollection, setMeta});
        },

        async fieldAddDays(key, value, id, collection, vuexCollection) {
            if (! collection) {
                collection = this.collection;
            }
            if (! vuexCollection) {
                vuexCollection = this.vuexCollection || collection;
            }
            if (! id) {
                id = this.recId;
            }
            this.$store.dispatch(`admin/${AdminActionTypes.AdminFieldAddDaysRequest}`, { collection, key, id, value, vuexCollection});
        },

        async fieldSubDays(key, value, id, collection, vuexCollection) {
            if (! collection) {
                collection = this.collection;
            }
            if (! vuexCollection) {
                vuexCollection = this.vuexCollection || collection;
            }
            if (! id) {
                id = this.recId;
            }
            return this.$store.dispatch(`admin/${AdminActionTypes.AdminFieldSubDaysRequest}`, { collection, key, id, value, vuexCollection});
        },

        async fieldPull(key, value, id, collection, vuexCollection) {
            if (! collection) {
                collection = this.collection;
            }
            if (! vuexCollection) {
                vuexCollection = this.vuexCollection || collection;
            }
            if (! id) {
                id = this.recId;
            }
            return this.$store.dispatch(`admin/${AdminActionTypes.AdminFieldPullRequest}`, { collection, key, id, value, vuexCollection});
        },

        async fieldPush(key, value, id, collection, vuexCollection) {
            if (! collection) {
                collection = this.collection;
            }
            if (! vuexCollection) {
                vuexCollection = this.vuexCollection || collection;
            }
            if (! id) {
                id = this.recId;
            }
            return this.$store.dispatch(`admin/${AdminActionTypes.AdminFieldPushRequest}`, { collection, key, id, value, vuexCollection});
        },

        async fieldAddToSet(key, value, id, collection) {
            if (! collection) {
                collection = this.collection;
            }
            if (! id) {
                id = this.recId;
            }
            return this.$store.dispatch(`admin/${AdminActionTypes.AdminFieldAddToSetRequest}`, { collection, key, id, value});
        },
    },
  };

  export default adminMixin;
