<template>
  <div>
     <q-toolbar class="bg-grey-3" style="min-height: 40px;">
      <q-toolbar-title shrink class="q-pr-xl">
        Admin
      </q-toolbar-title>

      <q-btn :to="{name: 'adminHome'}" size="md" no-caps flat >
        Home
      </q-btn>

      <q-btn :to="{name: 'adminStats'}" no-caps flat >
        Stats
      </q-btn>

      <q-btn nto="/admin/users/list/1" :to="{name: 'adminUsersList', params: { pageId: 1 }}" size="md" no-caps flat >
        Users
      </q-btn>

      <q-btn-dropdown
      split
      :to="{name: 'adminPostsList', params: { pageId: 1 }}"
      label="Posts"
      no-caps
      flat
    >
      <q-list>
        <q-item v-close-popup :to="{name: 'adminPostsPendingList', params: { pageId: 1 }}" class="text-black">
          <q-item-section>
            <q-item-label>Pending</q-item-label>
          </q-item-section>
        </q-item>

        <q-item v-close-popup clickable :to="{name: 'adminPostsExpiredList', params: { pageId: 1 }}"  class="text-black">
          <q-item-section>
            <q-item-label>Expired</q-item-label>
          </q-item-section>
          <q-item-section side top>
            <q-badge color="green" label="10" />
          </q-item-section>
        </q-item>

        <q-separator />

        <q-item v-close-popup :to="{name: 'adminJobApplicationsList', params: { pageId: 1 }}" class="text-black">
          <q-item-section>
            <q-item-label>Applications</q-item-label>
          </q-item-section>
        </q-item>


      </q-list>
    </q-btn-dropdown>


      <q-btn :to="{name: 'adminOrdersList', params: { pageId: 1 }}" size="md" no-caps flat >
        Orders
      </q-btn>

      <q-btn :to="{name: 'adminOrgsList', params: { pageId: 1 }}" size="md" no-caps flat >
        Orgs
      </q-btn>

      <q-btn :to="{name: 'adminCouponsList', params: { pageId: 1 }}" size="md" no-caps flat >
        Coupons
      </q-btn>

      <q-btn-dropdown
        split
        :to="{name: 'adminFeedbackList', params: { pageId: 1 }}"
        label="Feedback"
        no-caps
        flat
      >
        <q-list>
          <q-item v-close-popup :to="{name: 'adminServerErrorsList', params: { pageId: 1 }}" class="text-black">
            <q-item-section>
              <q-item-label>Errors</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-close-popup :to="{name: 'adminJSErrorsList', params: { pageId: 1 }}" class="text-black">
            <q-item-section>
              <q-item-label>JS Errors</q-item-label>
            </q-item-section>
            <q-item-section side top>
              <q-badge color="green" label="10" />
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>

      <q-btn-dropdown
        split
        :to="{name: 'adminMarketingHome'}"
        label="Marketing"
        no-caps
        flat
      >
        <q-list>
          <q-item v-close-popup :to="{name: 'adminMarketingEmailsList', params: { pageId: 1 }}" class="text-black">
            <q-item-section>
              <q-item-label>Emails</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-close-popup :to="{name: 'adminMarketingCampaignsList', params: { pageId: 1 }}" class="text-black">
            <q-item-section>
              <q-item-label>Cammpaigns</q-item-label>
            </q-item-section>
            <q-item-section side top>
              <q-badge color="green" label="1" />
            </q-item-section>
          </q-item>

        </q-list>
      </q-btn-dropdown>

      <q-btn-dropdown
        split
        :to="{name: 'adminOnboardingHome'}"
        label="Onboarding"
        no-caps
        flat
      >
        <q-list>

          <q-item v-close-popup :to="{name: 'adminOnboardingJobsList', params: { pageId: 1 }}" class="text-black">
            <q-item-section>
              <q-item-label>Jobs</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-close-popup :to="{name: 'adminCrawlerList', params: { pageId: 1 }}" class="text-black">
            <q-item-section>
              <q-item-label>Crawler</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-close-popup :to="{name: 'adminOnboardingOrgList', params: { pageId: 1 }}" class="text-black">
            <q-item-section>
              <q-item-label>Orgs / Employers</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-close-popup :to="{name: 'adminOnboardingJobseekersList', params: { pageId: 1 }}" class="text-black">
            <q-item-section>
              <q-item-label>Jobseekers</q-item-label>
            </q-item-section>
            <q-item-section side top>
              <q-badge color="green" label="10" />
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>

      <q-space></q-space>

      <q-toggle dense :value="hasRole('debug')" color="negative" icon="adb" @input="setDebug($event)" />


    </q-toolbar>

    <q-linear-progress v-if="loading" color="secondary" indeterminate />

    <q-page :padding="padding">
      <router-view />
    </q-page>
  </div>
</template>
<style scoped>
.v-progress-linear {
  margin-top: 0;
}
</style>
<script>
import { mapState, mapGetters } from 'vuex';
import { AdminActionTypes } from '../../store/action-types';

export default {
  computed: {
    ...mapState('post', {
      loading: 'loading',
    }),
    ...mapGetters('auth', {
      hasRole: 'hasRole',
    }),
    padding() {
      return this.$route.matched.some( m => m.meta.padding === false ) ? false: true;
    }
  },
  methods: {
    setDebug(val) {
      if (val) {
        this.$store.dispatch(`admin/${AdminActionTypes.AdminAddRole}`, 'debug');
      } else {
        this.$store.dispatch(`admin/${AdminActionTypes.AdminRemoveRole}`, 'debug');
      }
    }
  }
}
</script>

