<template>
<div>
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="Admin" to="/admin/home" />
      <q-breadcrumbs-el label="Pending Posts" @click="reqData(true)">
        <q-icon color="red" name="refresh"></q-icon>
      </q-breadcrumbs-el>
    </q-breadcrumbs>

  <q-toolbar class="bg-blue-2 q-mt-sm" style="min-height: 40px;">
      <q-toolbar-title shrink class="q-pr-xl">
        Total Pending Posts
      {{numRecs}}
      <span v-if="numRecs != numTotalRecs">
        of {{numTotalRecs}}
      </span>
      </q-toolbar-title>

      <q-input v-model="qry.val" label="Filter Text" dense >
        <template v-slot:append>
          <q-icon v-if="qry.val !== null" name="close" class="cursor-pointer" @click="qry.val = null" />
          <q-icon name="filter_list" @click="gotoPage(1)" />
        </template>
      </q-input>

      <q-input v-model="qry.field" label="Filter Field" class="q-mx-sm" dense >
      </q-input>


     <q-space></q-space>

    <q-pagination
      :value="currentPage"
      :max="numPages"
      :max-pages="5"
      :ellipses="false"
      :boundary-links="true"
      :boundary-numbers="false"
      :direction-links="true"
      @input="gotoPage"
    >
    </q-pagination>

  </q-toolbar>

  <table class="table table-striped table-sm full-width">
    <tr v-for="post in recs" :key="post._id">

    <td class="q-pt-xs text-no-wrap" valign="top">
      <div>
      {{ post.created_on | date  }}
      </div>
      <div class="text-xs">
      {{ post.posted_on | date  }}
      </div>
    </td>
    <td>
      <div class="text-sm">
        <router-link :to="{ name: 'adminPostsPendingView', params: { recId: post._id }}" class="text-primary">{{post.headline}}</router-link>
      </div>
      <div v-if="post.organization" class="text-xs">
        <router-link :to="{ name: 'adminOrgView', params: { recId: post.organization._id }}" class="text-primary">{{post.organization.name}}</router-link>
      </div>
    </td>
    <td>
      <div v-if="post.location">
        {{ post.location.city }},
        {{ post.location.state_code }},
      </div>
    </td>
    <td>
      <div v-if="post.user && post.user._id" class="text-sm">
         <user-name :user="post.user" />
      </div>
      <div v-else>
        Unknown
      </div>
    </td>

    <td class="text-right text-sm">
      <span v-if="post.order_info">
        {{post.order_info.total | currency}}
      </span>
    </td>

    <td class="text-right text-sm">
      <span v-if="post.order_info">
        <q-icon v-if="post.order_info.paid" name="fal fa-credit-card" color="primary"></q-icon>
      </span>
    </td>

    <td class="text-right text-sm">
      <q-icon v-if="post.verified" name="check" color="green"></q-icon>
      <q-icon v-if="!post.verified" name="close" color="red"></q-icon>
    </td>

    <td class="text-right text-sm">
      <q-icon v-if="post.active" name="check" color="green"></q-icon>
      <q-icon v-if="!post.active" name="close" color="red"></q-icon>
    </td>

    </tr>
  </table>

</div>
</template>

<script>
import pendPostModule from "src/modules/admin/store/module-admin-pending-post";
import storeRecListMixin from 'src/mixins/store_rec_list.mixin';

export default {
  mixins: [
    storeRecListMixin,
  ],
  data() {
    return {
      collection: 'pending_posts',
      vuexCollection: 'pending_posts.recs',

      vuexPath: 'admin/pending_posts',
      vuexPageRequestAction: pendPostModule.actionTypes.LoadRecordsPageRequest,
    }
  },

}
</script>
