<template>
<div>
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="Admin" to="/admin/home" />
      <q-breadcrumbs-el label="stats" />
    </q-breadcrumbs>

<div class="text-h2 q-my-md">
  General Stat Totals
  <q-btn flat size="sm" color="primary" icon="far fa-redo-alt" class="q-px-none" @click="fetchStats" />
</div>

<div>
<bar-chart :chart-data="chartData" :options="chartOptions"></bar-chart>
</div>

<div v-if="totals" class="q-my-md">
  <h5 class="q-my-sm flex items-stretch q-gutter-x-md">
    <div>
      Overall Totals
    </div>
  </h5>

  <table class="table table-striped table-sm full-width">
    <tr>
      <th></th>
      <th class="text-left">Start</th>
      <th class="text-left">End</th>
      <th class="text-right">Sign Ups</th>
      <th class="text-right">Posts</th>
      <th class="text-right">Prep Posts</th>
      <th class="text-right">Transactions</th>
    </tr>
    <tr v-for="stype in ['today', 'a_week_ago', 'week_so_far', 'last_week_so_far', 'last_week', 'month_so_far', 'last_month_so_far', 'last_month']" :key="stype">
      <th class="text-left">{{totals[stype].name}}</th>
      <td class="text-left">{{totals[stype].start_date | date('ddd MMM D, YYYY')}}</td>
      <td class="text-left">{{totals[stype].end_date | date('ddd MMM D, YYYY')}}</td>
      <td class="text-right">{{totals[stype].signups }}</td>
      <td class="text-right">{{totals[stype].posts }}</td>
      <td class="text-right">{{totals[stype].posts_prep }}</td>
      <td class="text-right">{{totals[stype].transactions }}</td>
    </tr>
  </table>


</div>

<div v-if="stats">
  <h5 class="q-my-sm flex items-stretch q-gutter-x-md">
    <div>
      Totals for
    </div>

    <div>
      <q-btn flat size="sm" color="primary" icon="far fa-angle-double-left" class="q-px-none" @click="daysAgo += 7" />
      <q-btn flat size="sm" color="primary" icon="far fa-angle-left" class="q-px-none" @click="daysAgo += 1" />

      <span v-if="daysAgo == 0">
        Today
      </span>
      <span v-else-if="daysAgo == 1">
        Yesterday
      </span>
      <span v-else>
        {{daysAgo}} Days Ago
      </span>

      <q-btn :disabled="daysAgo == 0" flat size="sm" color="primary" icon="far fa-angle-right" class="q-px-none" @click="daysAgo -= 1" />
      <q-btn :disabled="daysAgo <= 6" flat size="sm" color="primary" icon="far fa-angle-double-right" class="q-px-none" @click="daysAgo -= 7" />
      <q-btn :disabled="daysAgo == 0" flat size="xs" color="primary" icon="far fa-calendar-day" class="q-px-xs" @click="daysAgo = 0" />
    </div>


    <div class="inline text-body2 flex items-center">
    {{ stats.start_date | date(dateFormat) }}
    -
    {{ stats.end_date | date(dateFormat) }}
    </div>

  </h5>

   <q-tabs
      v-model="recentTab"
      dense
      class="bg-grey-3"
      active-color="primary"
      indicator-color="primary"
      align="left"
      narrow-indicator
    >
      <q-tab no-caps name="logins" :label="'Log Ins - ' + stats.totals.logins" />
      <q-tab no-caps name="posts_prep" :label="'Posts Prep - ' + stats.totals.posts_prep" />
      <q-tab no-caps name="posts" :label="'Posts - ' + stats.totals.posts" />
      <q-tab no-caps name="signups" :label="'Sign Ups - ' + stats.totals.signups" />
      <q-tab no-caps name="transactions">Transactions - {{stats.totals.transactions}}
        <span class="text-xs">{{ totalAmount | currency }}</span>
      </q-tab>
      <q-tab no-caps name="orders" :label="'Orders - ' + stats.totals.orders" />

    </q-tabs>

    <q-linear-progress v-if="fetching" color="secondary" indeterminate />

    <q-tab-panels v-model="recentTab" animated>

      <q-tab-panel class="q-pa-md" name="posts">
          <h5 class="q-my-none">Recent Posts - {{stats.recent.posts.count}}</h5>
          <table class="table table-striped table-sm full-width">
            <tr v-for="rec in stats.recent.posts.recs" :key="rec._id">
              <td>
                {{rec.created_on | date }}
              </td>

              <td>
              <div class="text-sm">
                <router-link :to="{ name: 'adminPostView', params: { recId: rec._id }}" class="text-primary">{{rec.headline}}</router-link>
              </div>
              <div v-if="rec.organization" class="text-xs">
                <router-link :to="{ name: 'adminOrgView', params: { recId: rec.organization._id }}" class="text-primary">{{rec.organization.name}}</router-link>
              </div>
            </td>
            <td>
              <div v-if="rec.location">
                {{ rec.location.city }},
                {{ rec.location.state_code }},
              </div>
            </td>
            <td>
              <div v-if="rec.user && rec.user._id" class="text-sm">
                <router-link :to="{ name: 'adminUsersView', params: { recId: rec.user._id }}" class="text-primary">{{rec.user.first_name}} {{rec.user.last_name}}</router-link>
              </div>
              <div v-else>
                Unknown
              </div>
            </td>

            <td class="text-right text-sm">
              <span v-if="rec.order_info">
                {{rec.order_info.total | currency}}
              </span>
            </td>

            <td class="text-right text-sm">
              <span v-if="rec.order_info">
                <q-icon v-if="rec.order_info.paid" name="fal fa-credit-card" color="primary"></q-icon>
              </span>
            </td>

            <td class="text-right text-sm">
              <q-icon v-if="rec.shared_fb" name="check" color="green"></q-icon>
              <q-icon v-if="!rec.shared_fb" name="close" color="red"></q-icon>
            </td>

            <td class="text-right text-sm">
              <q-icon v-if="rec.active" title="Active" name="check" color="green" @click="fieldSet('active', !rec.active, rec._id)"></q-icon>
              <q-icon v-if="!rec.active" title="Active" name="close" color="red" @click="fieldSet('active', !rec.active, rec._id)"></q-icon>
            </td>

            <td class="text-right text-sm">
              <q-icon v-if="rec.posted" title="Posted" name="fas fa-file-check" color="green" @click="fieldSet('posted', !rec.posted, rec._id)"></q-icon>
              <q-icon v-if="!rec.posted" title="Posted" name="fas fa-file-times" color="red" @click="fieldSet('posted', !rec.posted, rec._id)"></q-icon>
            </td>

            </tr>
          </table>

      </q-tab-panel>

      <q-tab-panel class="q-pa-md" name="posts_prep">
          <h5 class="q-my-none">Recent Posts Prep - {{stats.recent.posts_prep.count}}</h5>
          <table class="table table-striped table-sm full-width">
            <tr v-for="rec in stats.recent.posts_prep.recs" :key="rec._id">
              <td>
                <div>{{rec.created_on | date}}</div>
                <span class="text-xs">{{ rec.created_on | date("hh:mma")}}</span>
              </td>

              <td>
              <div class="text-sm">
                <router-link :to="{ name: 'adminPostView', params: { recId: rec._id }}" class="text-primary">{{rec.headline}}</router-link>
              </div>
              <div v-if="rec.organization" class="text-xs">
                <router-link :to="{ name: 'adminOrgView', params: { recId: rec.organization._id }}" class="text-primary">{{rec.organization.name}}</router-link>
              </div>
            </td>
            <td>
              <div v-if="rec.location">
                {{ rec.location.city }},
                {{ rec.location.state_code }},
              </div>
            </td>
            <td>
              <div v-if="rec.user && rec.user._id" class="text-sm">
                <router-link :to="{ name: 'adminUsersView', params: { recId: rec.user._id }}" class="text-primary">{{rec.user.first_name}} {{rec.user.last_name}}</router-link>
              </div>
              <div v-else>
                Unknown
              </div>
            </td>

            <td class="text-right text-sm">
              <span v-if="rec.order_info">
                {{rec.order_info.total | currency}}
              </span>
            </td>

            <td class="text-right text-sm">
              <span v-if="rec.order_info">
                <q-icon v-if="rec.order_info.paid" name="fal fa-credit-card" color="primary"></q-icon>
              </span>
            </td>

            <td class="text-right text-sm">
              <q-icon v-if="rec.shared_fb" name="check" color="green"></q-icon>
              <q-icon v-if="!rec.shared_fb" name="close" color="red"></q-icon>
            </td>

            <td class="text-right text-sm">
              <q-icon v-if="rec.active" title="Active" name="check" color="green" @click="fieldSet('active', !rec.active, rec._id)"></q-icon>
              <q-icon v-if="!rec.active" title="Active" name="close" color="red" @click="fieldSet('active', !rec.active, rec._id)"></q-icon>
            </td>

            <td class="text-right text-sm">
              <q-icon v-if="rec.posted" title="Posted" name="fas fa-file-check" color="green" @click="fieldSet('posted', !rec.posted, rec._id)"></q-icon>
              <q-icon v-if="!rec.posted" title="Posted" name="fas fa-file-times" color="red" @click="fieldSet('posted', !rec.posted, rec._id)"></q-icon>
            </td>

            </tr>
          </table>
      </q-tab-panel>

      <q-tab-panel name="signups">
        <h5 class="q-my-none">Recent User Sign Ups - {{stats.recent.signups.count}}</h5>
        <table class="table table-striped table-sm full-width text-sm">
          <tr v-for="user in stats.recent.signups.recs" :key="user._id">

          <td class="q-pt-xs text-no-wrap" valign="top">
            {{ user.created_on | date  }}
          </td>
          <td>
              <router-link v-if="user._id" :to="{ name: 'adminUsersView', params: { userId: user._id }}" class="text-primary">{{user.first_name}} {{user.last_name}}</router-link>
          </td>
          <td>
            {{user.email}}
          </td>
          <td>
            <q-chip v-for="role in user.roles" :key="role" size=".8em">{{role}}</q-chip>
          </td>
          <td class="text-right text-sm">
            <q-icon v-if="user.verified" name="check" color="green"></q-icon>
          </td>
          <td class="text-right text-sm">
            <q-icon v-if="user.active" name="check" color="green"></q-icon>
          </td>

          </tr>
        </table>
      </q-tab-panel>

      <q-tab-panel name="logins">
        <h5 class="q-my-none">Recent User Log Ins- {{stats.recent.logins.count}}</h5>
        <table class="table table-striped table-sm full-width text-sm">
          <tr v-for="user in stats.recent.logins.recs" :key="user._id">

          <td class="q-pt-xs text-no-wrap" valign="top">
            <age class="text-weight-bold" :val="user.created_on" />
            <span class="text-xs">
            {{ user.created_on | date  }}
            </span>
          </td>
          <td>
              <router-link v-if="user._id" :to="{ name: 'adminUsersView', params: { userId: user._id }}" class="text-primary">{{user.first_name}} {{user.last_name}}</router-link>
          </td>
          <td>
            {{user.email}}
          </td>
          <td>
            <q-chip v-for="role in user.roles" :key="role" size=".8em">{{role}}</q-chip>
          </td>
          <td class="text-right text-sm">
            <q-icon v-if="user.verified" name="check" color="green"></q-icon>
          </td>
          <td class="text-right text-sm">
            <q-icon v-if="user.active" name="check" color="green"></q-icon>
          </td>

          </tr>
        </table>
      </q-tab-panel>

      <q-tab-panel name="orders">
        <h5 class="q-my-none">Recent Orders - {{stats.recent.orders.count}}</h5>
          <table class="table table-striped table-sm full-width">
            <tr v-for="rec in stats.recent.orders.recs" :key="rec._id">
              <td>
                <div>{{rec.created_on | date}}</div>
                <span class="text-xs">{{ rec.created_on | date("hh:mma")}}</span>
              </td>

              <td>
              <div class="text-sm">
                <router-link :to="{ name: 'adminPostView', params: { recId: rec.post._id }}" class="text-primary">{{rec.post.headline}}</router-link>
              </div>
              <div v-if="rec.organization" class="text-xs">
                <router-link :to="{ name: 'adminOrgView', params: { recId: rec.organization._id }}" class="text-primary">{{rec.organization.name}}</router-link>
              </div>
            </td>

            <td>
                <router-link v-if="rec._id" :to="{ name: 'adminOrdersView', params: { recId: rec._id }}" class="text-primary">{{rec._id}}</router-link>
            </td>
            <td>
              {{rec.total | currency }}
            </td>
            <td class="text-right text-sm">
              <q-icon v-if="rec.paid" name="fal fa-credit-card" color="green"></q-icon>
            </td>

          </tr>
        </table>
      </q-tab-panel>

      <q-tab-panel name="transactions">
        <h5 class="q-my-none">Recent Transactions - {{stats.recent.transactions.count}}</h5>
          <table class="table table-sm full-width">
            <tr v-for="rec in stats.recent.transactions.recs" :key="rec._id" :class="{'bg-green-1': rec.success, 'bg-red-1': !rec.success}">
              <td style="width: 110px;">
                <div>{{rec.created_on | date}}</div>
                <span class="text-xs">{{ rec.created_on | date("hh:mma")}}</span>
              </td>

              <td style="width: 50px;">
                {{rec.invoice_number}}
              </td>

              <td>
              <div v-if="rec.post" class="text-sm">
                <router-link :to="{ name: 'adminPostView', params: { recId: rec.post._id }}" class="text-primary">{{rec.post.headline}}</router-link>
              </div>
              <div v-if="rec.organization" class="text-xs">
                <router-link :to="{ name: 'adminOrgView', params: { recId: rec.organization._id }}" class="text-primary">{{rec.organization.name}}</router-link>
              </div>
            </td>

            <td>
                <router-link v-if="rec._id" :to="{ name: 'adminOrdersView', params: { recId: rec._id }}" class="text-primary">{{rec._id}}</router-link>
            </td>

              <td style="width: 50px;">
                {{rec.trans_id}}
              </td>

              <td style="width: 50px;">
                <span v-if="rec.error_code">
                  Err {{rec.error_code}}
                  <q-tooltip>
                    {{rec.error_text}}
                  </q-tooltip>
                </span>
              </td>

            <td>
              {{rec.amount | currency }}
            </td>
            <td class="text-right text-sm">
              <q-icon name="fal fa-credit-card" :color="rec.success ? 'green' : 'grey'"></q-icon>
            </td>

          </tr>
        </table>
      </q-tab-panel>

    </q-tab-panels>
</div>

</div>
</template>

<script>
import ApiService from "src/services/api.service";
import BarChart from 'src/components/bar-chart';

export default {
  components: {
    'BarChart': BarChart,
  },
  data() {
    return {
      recentTab: 'logins',
      daysAgo: 0,
      dateFormat: 'ddd MMMM Do',
      fetching: false,
      stats: null,
      totals: null,

      chartData: {},
      ochartData: {
        labels: [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday'
        ],
        datasets: [],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: "top"
        },
        title: {
          display: true,
          text: "Chart.js Bar Chart"
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      },

    };
  },
  computed: {
    totalAmount() {
      if (Array.isArray(this.stats.recent.transactions.recs)) {
        return this.stats.recent.transactions.recs
              .filter( a => a.success )
              .map( a => a.amount )
              .reduce( (a,b) => a + b, 0);
      }

      return 0;
    }
  },
  watch: {
    'daysAgo': function (val) {
      this.fetchStats();
      if (val < 300) {
        this.dateFormat = 'ddd MMMM Do';
      } else {
        this.dateFormat = 'ddd MMMM Do YYYY';
      }
    },
  },
  created() {
    this.fetchStats();
  },
  methods: {
    async fetchStats() {
      console.log('fetch stats', this.daysAgo);

      this.fetching = true;

      try {
        let apiData = await ApiService.get('/api/stats/totals/0');
        this.totals = apiData.data;
        apiData = await ApiService.get('/api/stats/today/' + this.daysAgo);
        this.stats = apiData.data;

        // set up chart data.
        this.chartData = {
          labels: [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday'
          ],
          datasets: this.totals.tallies.users.chartData
        };
        this.chartOptions.title.text = 'Sign Ups'

        console.log('datasets', this.datasets);


        return true;
      } catch (err) {
        console.log("Error: ", err.message);
        return false;
      } finally {
        this.fetching = false;
      }


    },
  }
}
</script>
