<template>
<div>
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="Admin" to="/admin/home" />
      <q-breadcrumbs-el label="Coupons" to="/admin/coupons/list/1" />
      <q-breadcrumbs-el :label="$route.params.recId" />
      <q-breadcrumbs-el label="View">
        <q-btn dense flat color="red" size="sm" icon="refresh" @click="refreshRec(true)" />
      </q-breadcrumbs-el>
    </q-breadcrumbs>

<div class="row q-my-sm">
  <div class="col-12 text-h5">
    <q-icon name="refresh" class="q-mx-md" color="red" @click="refreshRec()"></q-icon>
    {{rec.name}}
    <q-chip class="bg-red-5 text-white" size=".9em" square>{{rec.code}}</q-chip> <span v-if="rec.amount_type == 'dollars'">$</span>{{ rec.amount }}<span v-if="rec.amount_type == 'percent'">%</span> off
  </div>
  <div class="col-12">

    <q-chip square dense clickable @click="fieldSet('reuse', !rec.reuse)" >
      <q-avatar v-show="rec.reuse" color="green" text-color="white" icon="check" />
      <q-avatar v-show="!rec.reuse" color="red" text-color="white" icon="close" />
      Reuse
    </q-chip>

    <q-chip square dense clickable @click="fieldSet('active', !rec.active)" >
      <q-avatar v-show="rec.active" color="green" text-color="white" icon="check" />
      <q-avatar v-show="!rec.active" color="red" text-color="white" icon="close" />
      Active
    </q-chip>
  </div>

  <div class="col-12">
    Created On: {{rec.created_on | date('MMM D, YYYY hh:mma')}}
  </div>

</div>


<div v-if="rec" class="q-mt-xl">

<div class="striped">
<div class="row">
  <div class="col-1 text-bold q-pa-md">
    Id
  </div>
  <div class="col-1 q-py-md">
      {{rec._id}}
  </div>
</div>

<div class="row">
  <div class="col-1 text-bold q-pa-md">
    Name
  </div>
  <div class="col-auto">
    <q-input v-model="rec.name" class="" dense>
        <template v-slot:after>
          <q-icon name="fas fa-cloud-upload" @click="fieldSet('name', rec.name, rec._id)" />
        </template>
    </q-input>
  </div>
</div>

<div class="row">
  <div class="col-1 text-bold q-pa-md">
    Code
  </div>
  <div class="col-auto">
    <q-input v-model="rec.code" class="" dense>
        <template v-slot:after>
          <q-icon name="fas fa-cloud-upload" @click="fieldSet('code', rec.code, rec._id)" />
        </template>
    </q-input>
  </div>
</div>

<div class="row">
  <div class="col-1 text-bold q-pa-md">
    Amount
  </div>
  <div class="col-auto">
     <q-input
          ref="amount"
          v-model.number="rec.amount"
          :prefix="amountPrefixChar"
          :suffix="amountSuffixChar"
          label="Amount"
          type="number"
          step="0.05"
          dense
          :rules="[
            val => !!val || 'Field is required',
            val => getServerError('amount'),
          ]"
          @blur="clearServerError('amount')"
        >
          <template v-slot:prepend>
            <q-icon v-show="rec.amount_type == 'dollars'" name="fal fa-dollar-sign" class="cursor-pointer" />
            <q-icon v-show="rec.amount_type == 'percent'" name="fal fa-percent" class="cursor-pointer" />
          </template>

          <template v-slot:append>
          <q-chip square dense clickable @click="toggleAmountType()">
            <q-avatar v-show="rec.amount_type != 'dollars'" color="primary" text-color="white" icon="fal fa-dollar-sign" />
            <q-avatar v-show="rec.amount_type == 'dollars'" color="primary" text-color="white" icon="fal fa-percent" />
            Toggle Type
          </q-chip>
          </template>
          <template v-slot:after>
            <q-icon name="fas fa-cloud-upload" @click="fieldSet('amount', rec.amount, rec._id)" />
          </template>
        </q-input>

  </div>
</div>


<div class="row">
  <div class="col-1 text-bold q-pa-md">
    Expires On
  </div>
  <div class="col-1 q-py-md text-no-wrap">
      {{rec.expires_on | date('MMM D, YYYY hh:mma')}}
  </div>

        <div class="col-auto q-mx-md q-px-sm" style="max-width: 300px">
            <q-input v-model="rec.expires_on">
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                    <q-date v-model="rec.expires_on" mask="YYYY-MM-DDTHH:mm:ss[Z]"  @input="() => { $refs.qDateProxy.hide(); $refs.qTimeProxy.show(); }" />
                  </q-popup-proxy>
                </q-icon>
                <q-icon name="access_time" class="cursor-pointer">
                  <q-popup-proxy ref="qTimeProxy" transition-show="scale" transition-hide="scale">
                    <q-time v-model="rec.expires_on" mask="YYYY-MM-DDTHH:mm:ss[Z]"  @input="() => $refs.qTimeProxy.hide()" />
                  </q-popup-proxy>
                </q-icon>
                <q-icon name="close" class="cursor-pointer" @click="fieldSet('expires_on', null, rec._id)">
                </q-icon>

                <q-icon name="fas fa-cloud-upload" @click="fieldSet('expires_on', rec.expires_on, rec._id)" />
              </template>
            </q-input>
        </div>
    </div>


<div class="row">
  <div class="col-1 text-bold q-pa-md">
    Active
  </div>
  <div class="col-auto flex items-center">
    <q-toggle v-model="rec.active" dense color="positve" icon="check" @input="fieldSet('active', $event, rec._id)" />
  </div>
</div>

<div class="row">
  <div class="col-1 text-bold q-pa-md">
    Reuse
  </div>
  <div class="col-auto flex items-center">
    <q-toggle v-model="rec.reuse" dense color="positve" icon="check" @input="fieldSet('reuse', $event, rec._id)" />
  </div>
</div>

<div class="row">
  <div class="col-1 text-bold q-pa-md">
    Created On
  </div>
  <div class="col-1 q-py-md text-no-wrap">
      {{rec.created_on | date('MMM D, YYYY hh:mma')}}
  </div>
</div>

<div class="row">
  <div class="col-1 text-bold q-pa-md">
    Description
  </div>
  <div class="col-4">
    <q-input v-model="rec.description" class="" type="textarea" dense>
        <template v-slot:after>
          <q-icon name="fas fa-cloud-upload" @click="fieldSet('description', rec.description, rec._id)" />
        </template>
    </q-input>
  </div>
</div>


</div>


<div class="row q-my-xl">





  <table class="table table-striped table-sm full-width">
    <tr>
      <th style="width: 180px;" class="text-left">Id</th>
      <td>
       {{rec._id }}
      </td>
    </tr>

    <tr>
      <th style="width: 180px;" class="text-left">Name</th>
      <td>
       {{rec.name }}
      </td>
    </tr>

    <tr>
      <th style="width: 180px;" class="text-left">Code</th>
      <td>
       {{rec.code }}
      </td>
    </tr>

    <tr>
      <th style="width: 180px;" class="text-left">Amount</th>
      <td>
       <span v-if="rec.amount_type == 'dollars'">$</span>{{ rec.amount }}<span v-if="rec.amount_type == 'percent'">%</span>
      </td>
    </tr>

    <tr>
      <th style="width: 180px;" class="text-left">Active</th>
      <td>
       {{rec.active }}
      </td>
    </tr>

    <tr>
      <th style="width: 180px;" class="text-left">Reuse</th>
      <td>
       {{rec.reuse }}
      </td>
    </tr>

    <tr>
      <th style="width: 180px;" class="text-left">Count</th>
      <td>
       {{rec.use_count }} of {{rec.use_limit }}
      </td>
    </tr>

    <tr>
      <th style="width: 180px;" class="text-left">Name</th>
      <td>
       {{rec.name }}
      </td>
    </tr>

    <tr>
      <th style="width: 180px;" class="text-left">Expires On</th>
      <td>
       {{rec.expires_on | date('MMM D, YYYY hh:mma')}}
      </td>
    </tr>

    <tr>
      <th style="width: 180px;" class="text-left">Created On</th>
      <td>
       {{rec.created_on | date('MMM D, YYYY hh:mma')}}
      </td>
    </tr>

    <tr>
      <th style="width: 180px;" class="text-left">Description</th>
      <td>
       <q-icon name="edit" clickable @click="toggleForm('description')" />
       {{ rec.description }}

       <div v-show="showForms.description == true">
         <q-icon name="close" clickable @click="toggleForm('description')" />
         Edit

       </div>
      </td>
    </tr>

  </table>
</div>

<div class="text-h5">Management Tools</div>

<q-toolbar class="bg-grey-3 text-white q-my-md shadow-2 row q-col-gutter-xs">
  <div class="col-3">
    <q-select v-model="setField" :options="recKeys.sort()" dense borderless class="" @input="setValue = getPathValue($event)" />
  </div>

  <div v-if="setType === 'Bool'" class="col-2">
    <q-toggle v-if="setType === 'Bool'" v-model="setValue" dense />
  </div>

  <div v-if="setType !== 'Bool'" class="col-3">
     <q-input
        v-if="setType !== 'Bool'"
        v-model="setValue"
        borderless
        dense
        class="q-pa-xs"
        :rules="[ val => val && val.length > 0 || 'Please type something']"
      >
    </q-input>
  </div>


  <div class="col-1">
    <q-select v-model="setType" :options="['Str', 'Bool', 'Number', 'JSON']" dense borderless />
  </div>

  <div class="col-2">
    <q-btn class="q-ma-md" size="sm" label="Set Value" color="secondary" @click="fieldSet(setField, setValue)"></q-btn>
  </div>


</q-toolbar>

</div>


  <div class="row q-my-xl">
      <div class="col-12 q-px-sm">
        <show-data :data="rec"></show-data>
      </div>
  </div>


</div>
</template>

<script>
import { get as loadashGet } from 'lodash';
import * as DotObject from 'dot-object';
import { mapState, mapGetters } from 'vuex'
import { adminMixin } from '../admin.mixin';
import storeRecViewMixin from 'src/mixins/store_rec_view.mixin';
import adminCouponsModule from "src/modules/admin/store/module-admin-coupon";

export default {
  mixins: [
    adminMixin,
    storeRecViewMixin,
  ],
  data() {
    return {
      amountPrefixChar: null,
      amountSuffixChar: null,
      setField: null,
      setType: 'Str',
      setValue: null,
      showForms: {
        description: false,
      },
      recId: this.$route.params.recId,
      collection: 'coupons',
      vuexCollection: 'coupons.recs',
      vuexPath: 'admin/coupons',
      vuexGetRecordAction: adminCouponsModule.actionTypes.GetRecordRequest,


    };
  },
  computed: {
    ...mapState('admin/coupons', {
        loading: 'loading',
      }),
    ...mapGetters('admin/coupons', [
      'getRecById',
    ]),
    recKeys() {
      return this.coupon ? Object.keys( DotObject.dot(this.coupon) ) : [];
    },
  },
  created() {
    this.refreshRec();
  },
  methods: {
    toggleForm(formName) {
      this.showForms[formName] = !this.showForms[formName];
      console.log('showForm', formName, this.showForms[formName]);
    },

    updateValue(v, evt) {
      this[v] = evt.target.value;
    },
    toggleAmountType() {
      if (this.rec.amount_type != 'dollars') {
        this.fieldSet('amount_type', 'dollars', this.rec._id);
        // this.amountSuffixChar = null;
        // this.amountPrefixChar = '$';
        // this.rec.amount_type = 'dollars';
      } else {
        console.log('running percent');
        this.fieldSet('amount_type', 'percent', this.rec._id);
        // this.amountSuffixChar = '%';
        // this.amountPrefixChar = null;
        // this.rec.amount_type = 'percent';
      }
    },
    getPathValue(path) {
      const val = loadashGet(this.coupon, path);
      console.log('getPathValue', path, val);
      if (val === true || val === false) {
        this.setType = 'Bool';
      } else {
        this.setType = 'Str';
      }

      return val;
    }

  }

}
</script>
