<template>
<div>
  <div class="text-h4">Email Tools</div>

  <div class="q-my-lg">
    <div class="text-h5">Update Email Status</div>

    <div class="row">
      <div class="col-12 col-sm-6 col-md-6">
        <q-input v-model="emailStatus.cur_email"  label="Current Email" />
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-6 col-md-6">
         <q-select
                      v-model="emailStatus.status"
                      :options="statusTypes"
                      dense
                      label="Status"
                      :rules="[
                          val => !!val || 'Field is required',
                        ]"
                    />
      </div>
    </div>

    <div class="row q-my-sm">
      <div class="col-12 col-sm-6 col-md-6">
        <q-btn label="Set Email Status" color="primary" :loading="settingStatus" @click="setEmailStatus()" />
      </div>
    </div>
  </div>

  <div class="q-my-lg">
    <div class="text-h5">Replace Email</div>

    <div class="row">
      <div class="col-12 col-sm-6 col-md-6">
        <q-input v-model="replaceEmail.cur_email"  label="Current Email" />
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-6 col-md-6">
        <q-input v-model="replaceEmail.new_email"  label="New Email" />
      </div>
    </div>

    <div class="row q-my-sm">
      <div class="col-12 col-sm-6 col-md-6">
        <q-btn label="Update Email" color="primary" @click="updateEmail()" />
      </div>
    </div>
  </div>

  <div class="q-my-lg">
    <div class="text-h5">Add Email</div>

    <div class="row">
      <div class="col-12 col-sm-6 col-md-6">
        <q-input v-model="newEmail.first_name"  label="First Name" />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6 col-md-6">
        <q-input v-model="newEmail.last_name"  label="Last Name" />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6 col-md-6">
        <q-input v-model="newEmail.email"  label="Email" />
      </div>
    </div>

    <div class="row q-my-sm">
      <div class="col-12 col-sm-6 col-md-6">
        <q-btn label="Add New Email" color="primary" @click="addEmail()" />
      </div>
    </div>
  </div>


</div>
</template>
<script>
import { mapState } from 'vuex';
import { notifyError, notifySuccess } from 'src/services/utils';
import { ApiService } from 'src/services/api.service'

export default {
  data() {
    return {
      statusTypes: ['unsubscribed', 'bounced'],
      settingStatus: false,
      emailStatus: {
        cur_email: null,
        status: 'unsubscribed',
      },
      replacing: false,
      adding: false,
      newEmail: {
        first_name: null,
        last_name: null,
        email: null,
      },
      replaceEmail: {
        cur_email: null,
        new_email: null,
      },
    };

  },
  computed: {
    ...mapState('post', {
      loading: 'loading',
    }),
  },
  methods: {
    async setEmailStatus() {
      this.settingStatus = true;

      try {
        await ApiService.post("/api/admin/marketing/emails/status", this.emailStatus );
        notifySuccess('Successfully updated email');
        this.emailStatus.cur_email = null;
        return true;
      } catch (err) {
        this.settingStatus = false;

        if (err.response) {
          notifyError('Error getting rendered email ', err);
        } else {
          console.log("Error: ", err);
          throw err;
        }

        return false;
      } finally {
        this.settingStatus = false;
      }

    },

    async updateEmail() {
      this.replacing = true;

      try {
        await ApiService.post("/api/admin/marketing/emails/replace", this.replaceEmail );
        notifySuccess('Successfully updated email');
        this.replaceEmail = {
          cur_email: null,
          new_email: null,
        };
        return true;
      } catch (err) {
        this.replacing = false;

        if (err.response) {
          notifyError('Error getting rendered email ', err);
        } else {
          console.log("Error: ", err);
          throw err;
        }

        return false;
      } finally {
        this.replacing = false;
      }

    },

    async addEmail() {
      this.adding = true;

      try {
        await ApiService.post("/api/admin/marketing/emails/add", this.newEmail );
        notifySuccess('Successfully updated email');
        this.newEmail = {
          email: null,
          first_name: null,
          last_name: null,
        };
        return true;
      } catch (err) {
        this.adding = false;

        if (err.response) {
          notifyError('Error getting rendered email ', err);
        } else {
          console.log("Error: ", err);
          throw err;
        }

        return false;
      } finally {
        this.adding = false;
      }

    },

  },
}
</script>

