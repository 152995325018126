<template>
<div>
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="Admin" to="/admin/home" />
      <q-breadcrumbs-el label="Orgs" to="/admin/orgs/list/1" />
      <q-breadcrumbs-el :label="$route.params.recId" />
      <q-breadcrumbs-el label="View">
        <q-btn dense flat color="red" size="sm" icon="refresh" @click="refreshRec(true)" />
      </q-breadcrumbs-el>
    </q-breadcrumbs>

<div v-if="rec">


<div class="text-h5">Organization Information</div>
<div class="striped q-mb-md">
  <div class="row">
    <div class="col-2 q-py-xs">Id</div>
    <div class="col-10 q-py-xs">{{rec._id}}</div>
  </div>

  <div class="row">
    <div class="col-2 q-py-xs">Name</div>
    <div class="col-10 q-py-xs">{{rec.name}}</div>
  </div>

  <div class="row">
    <div class="col-2 q-py-xs">Reviewed</div>
    <div class="col-10"><q-chip square dense clickable @click="fieldSet('reviewed', !rec.reviewed)" >
      <q-avatar v-show="rec.reviewed" color="green" text-color="white" icon="check" />
      <q-avatar v-show="!rec.reviewed" color="red" text-color="white" icon="close" />
      Reviewed
    </q-chip>
          <q-toggle v-model="rec.need_review" dense color="positve" icon="check" @input="fieldSet('need_review', $event, rec._id)" />
    </div>
  </div>

  <div class="row">
    <div class="col-2 q-py-xs">Need Review</div>
    <div class="col-10">
      <q-toggle v-model="rec.need_review" dense color="positve" icon="check" @input="fieldSet('need_review', $event, rec._id)" />
    </div>
  </div>

  <div class="row">
    <div class="col-2 q-py-xs flex items-center">Active</div>
    <div class="col-10"><q-chip square dense clickable @click="fieldSet('active', !rec.active)" >
      <q-avatar v-show="rec.active" color="green" text-color="white" icon="check" />
      <q-avatar v-show="!rec.active" color="red" text-color="white" icon="close" />
      Active
    </q-chip></div>
  </div>

  <div class="row">
    <div class="col-2 q-py-xs">Created On</div>
    <div class="col-10 q-py-xs">{{rec.created_on | date}}</div>
  </div>


</div>

<div class="text-h5">Organization Posts <q-btn dense flat color="red" size="sm" icon="refresh" @click="fetchPosts" /></div>
<div class="striped q-mb-md">
  <div v-for="post in orgPosts" :key="post._id" class="row flex">
    <div class="col col-shrink q-py-xs" style="min-width: 100px;">
      {{post.posted_on | date}}
    </div>
    <div class="col q-py-xs">
      <router-link :to="{name: 'adminPostView', params: {recId: post._id}}">{{post.headline}}</router-link>
    </div>
    <div class="col col-auto q-py-xs">
      {{post._id}}
    </div>
  </div>
</div>




<div class="q-mt-xl">

<div class="striped">

<div class="row">
  <div class="col-1 text-bold q-pa-md">
    Name
  </div>
  <div class="col-auto">
    <q-input v-model="rec.name" class="" dense>
        <template v-slot:after>
          <q-icon name="fas fa-cloud-upload" @click="fieldSet('name', rec.name, rec._id)" />
        </template>
    </q-input>
  </div>
</div>

<div class="row">
  <div class="col-1 text-bold q-pa-md">
    Mission
  </div>
  <div class="col-4">
    <q-input v-model="rec.mission" class="" type="textarea" dense>
        <template v-slot:after>
          <q-icon name="fas fa-cloud-upload" @click="fieldSet('mission', rec.mission, rec._id)" />
        </template>
    </q-input>
  </div>
</div>


</div>


<div class="row q-my-xl">

</div>






<div class="text-h5">Management Tools</div>

<q-toolbar class="bg-grey-3 text-white q-my-md shadow-2 row q-col-gutter-xs">
  <div class="col-3">
    <q-select v-model="setField" :options="recKeys.sort()" dense borderless class="" @input="setValue = getPathValue($event)" />
  </div>

  <div v-if="setType === 'Bool'" class="col-2">
    <q-toggle v-if="setType === 'Bool'" v-model="setValue" dense />
  </div>

  <div v-if="setType !== 'Bool'" class="col-3">
     <q-input
        v-if="setType !== 'Bool'"
        v-model="setValue"
        borderless
        dense
        class="q-pa-xs"
        :rules="[ val => val && val.length > 0 || 'Please type something']"
      >
    </q-input>
  </div>


  <div class="col-1">
    <q-select v-model="setType" :options="['Str', 'Bool', 'Number', 'JSON']" dense borderless />
  </div>

  <div class="col-2">
    <q-btn class="q-ma-md" size="sm" label="Set Value" color="secondary" @click="fieldSet(setField, setValue)"></q-btn>
  </div>


</q-toolbar>

</div>


  <div class="row q-my-xl">
      <div class="col-12 q-px-sm">
        <show-data :data="rec"></show-data>
      </div>
  </div>


</div>

<div v-else>
  No Rec
</div>

</div>
</template>

<script>
import { mapState } from 'vuex'
import { adminMixin } from '../admin.mixin';
import { get as loadashGet } from 'lodash';
import * as DotObject from 'dot-object';

import storeRecViewMixin from 'src/mixins/store_rec_view.mixin';

import adminPostsModule from "src/modules/admin/store/module-admin-post";
import adminOrgsModule from "src/modules/admin/store/module-admin-org";

export default {
  mixins: [
    adminMixin,
    storeRecViewMixin,
  ],
  data() {

    return {
      amountPrefixChar: null,
      amountSuffixChar: null,
      setField: null,
      setType: 'Str',
      setValue: null,

      collection: 'organizations',
      vuexCollection: 'orgs.recs',

      vuexPath: 'admin/orgs',
      vuexGetRecordAction: adminOrgsModule.actionTypes.GetRecordRequest,

      showForms: {
        description: false,
      },
      recId: this.$route.params.recId,
    };
  },
  computed: {
    ...mapState('admin/orgs', {
        orgLoading: 'loading',
      }),

    orgPosts() {
      return  this.$store.getters['admin/posts/getRecsByOrgId'](this.$route.params.recId);
    },
    recKeys() {
      return this.rec ? Object.keys( DotObject.dot(this.rec) ) : [];
    },
  },
  created() {
    this.refreshRec();
  },
  methods: {
    toggleForm(formName) {
      this.showForms[formName] = !this.showForms[formName];
      console.log('showForm', formName, this.showForms[formName]);
    },
    updateValue(v, evt) {
      this[v] = evt.target.value;
    },
    fetchPosts() {
      // this.becomingUser = true;
      this.$store.dispatch(`admin/posts/${adminPostsModule.actionTypes.LoadRecordsPageRequest}`, {
          qry: {
            field: 'organization._id',
            val: this.rec._id,
          },
          page_id: 1,
         });
    },
    toggleAmountType() {
      if (this.rec.amount_type != 'dollars') {
        this.fieldSet('amount_type', 'dollars', this.rec._id);
        // this.amountSuffixChar = null;
        // this.amountPrefixChar = '$';
        // this.rec.amount_type = 'dollars';
      } else {
        console.log('running percent');
        this.fieldSet('amount_type', 'percent', this.rec._id);
        // this.amountSuffixChar = '%';
        // this.amountPrefixChar = null;
        // this.rec.amount_type = 'percent';
      }
    },
    getPathValue(path) {
      const val = loadashGet(this.rec, path);
      console.log('getPathValue', path, val);
      if (val === true || val === false) {
        this.setType = 'Bool';
      } else {
        this.setType = 'Str';
      }

      return val;
    }

  }

}
</script>
