<template>
<div>
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="Admin" to="/admin/home" />
      <q-breadcrumbs-el label="Marketing" to="/admin/marketing/home" />
      <q-breadcrumbs-el label="Campaigns" @click="reqData(true)">
          &nbsp; <q-icon color="red" name="refresh"></q-icon>
      </q-breadcrumbs-el>
      <q-breadcrumbs-el>
        <q-btn flat dense size="xs" @click="$router.push({name: 'adminMarketingCampaignsAdd'})">
          <q-icon color="red" name="add"></q-icon>
        </q-btn>
      </q-breadcrumbs-el>
    </q-breadcrumbs>

  <q-toolbar class="bg-blue-2" style="min-height: 40px;">
      <q-toolbar-title shrink class="q-pr-xl">
        Total Campaign Recs
        {{numRecs}}
      <span v-if="numRecs != numTotalRecs">
        of {{numTotalRecs}}
      </span>
      </q-toolbar-title>

      <q-input v-model="qry.val" label="Filter Text" dense >
        <template v-slot:append>
          <q-icon v-if="qry.val !== null" name="close" class="cursor-pointer" @click="qry.val = null" />
          <q-icon name="filter_list" @click="gotoPage(1)" />
        </template>
      </q-input>

      <q-input v-model="qry.field" label="Filter Field" class="q-mx-sm" dense >
      </q-input>

     <q-space></q-space>

    <q-pagination
      :value="currentPage"
      :max="numPages"
      :max-pages="5"
      :ellipses="false"
      :boundary-links="true"
      :boundary-numbers="false"
      :direction-links="true"
      @input="gotoPage"
    >
    </q-pagination>

  </q-toolbar>

  <table class="table table-striped table-sm full-width">
    <tr>
      <th></th>
      <th></th>
      <th>Created</th>
      <th>Send On</th>
      <th>Name</th>

      <th style="text-align: right; width: 80px;">Users</th>
      <th style="text-align: right; width: 80px;">Emails</th>
      <th style="text-align: right; width: 80px;">Views</th>
      <th style="text-align: right; width: 80px;">Clicks</th>
      <th style="text-align: right; width: 80px;">Bounces</th>
    </tr>

    <tr v-for="campaign in recs" :key="campaign._id">

    <td class="q-pt-xs text-no-wrap" style="width: 30px;">
      <q-checkbox v-model="ids" :val="campaign._id" dense color="green" />
    </td>

    <td class="q-pt-xs text-no-wrap" style="width: 30px;">
      <router-link :to="{name: 'adminMarketingCampaignsEdit', params: {campaignId: campaign._id}}"><q-icon name="fas fa-pencil" /></router-link>
    </td>

    <td class="q-pt-xs text-no-wrap" style="width: 90px;">
      {{ campaign.created_on | date }}
    </td>

    <td class="q-pt-xs text-no-wrap" style="width: 90px;">
      {{ campaign.send_on | date }}
    </td>

    <td>
        <router-link v-if="campaign._id" :to="{ name: 'adminMarketingCampaignsView', params: { recId: campaign._id }}" class="text-primary">{{campaign.name}}</router-link>
    </td>

    <td class="text-right text-sm" style="width: 80px;">{{campaign.stats.users}}</td>
    <td class="text-right text-sm" style="width: 80px;">{{campaign.stats.emails}}</td>
    <td class="text-right text-sm" style="width: 80px;">{{campaign.stats.views}}</td>
    <td class="text-right text-sm" style="width: 80px;">{{campaign.stats.clicks}}</td>
    <td class="text-right text-sm" style="width: 80px;">{{campaign.stats.bounces}}</td>

    </tr>
  </table>

</div>
</template>

<style scoped>
tr:hover {
  background-color: #BBDEFB;
}
</style>

<script>
import { mapState } from 'vuex';
import { debounce } from "lodash";
import { adminMixin } from '../../admin.mixin';

import adminMarketingCampaignsModule from "src/modules/admin/store/module-admin-marketing/module-admin-marketing-campaigns";
import storeRecListMixin from 'src/mixins/store_rec_list.mixin';

export default {
  mixins: [
    adminMixin,
    storeRecListMixin,
  ],
  data() {
    return {
      collection: 'marketing_campaigns',
      vuexCollection: 'marketing.campaigns.recs',
      vuexPath: 'admin/marketing/campaigns',
      vuexPageRequestAction: adminMarketingCampaignsModule.actionTypes.LoadRecordsPageRequest,

      current: 1,
      ids: [],

    }
  },

  computed: {
      ...mapState('admin/marketing/campaigns', {
        loading: 'loading',
        loaded: 'loaded',
      }),
  },

  watch: {
  },

  created () {
    this.reqData();
  },

  methods: {
    selectAll() {
      this.ids = this.campaigns.map( x => x._id );
    },
  },
}
</script>
