<template>
<div>
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="Admin" to="/admin/home" />
      <q-breadcrumbs-el label="Posts" @click="reqData(true)">
        <q-icon color="red" name="refresh"></q-icon>
      </q-breadcrumbs-el>
    </q-breadcrumbs>


       <q-select
              v-if="fbAccounts.length > 0"
              v-model="fbSelect"
              :options="fbAccounts"
              option-label="name"
              dense
              label="FB Page"
              style="width: 300px;"
              @input="fbPageAccount = $event"
            />

      <q-btn flat color="primary" label="show" no-caps @click="showAccount()"></q-btn>
      <q-btn v-if="accessToken" size="sm" color="primary" @click="fbLogout()">
        <q-icon name="fab fa-facebook-f"></q-icon>
        &nbsp;
        Log Out
      </q-btn>

      <q-btn v-if="! accessToken" color="primary" size="sm" class="q-px-xs" @click="fbLogin()">
        <q-icon name="fab fa-facebook-f"></q-icon>
        &nbsp;
        Connect to Facebook
      </q-btn>


  <q-toolbar class="bg-blue-2 q-mt-sm" style="min-height: 40px;">
      <q-toolbar-title shrink class="q-pr-xl">
        Total Posts
      {{numRecs}}
      <span v-if="numRecs != numTotalRecs">
        of {{numTotalRecs}}
      </span>
      </q-toolbar-title>

      <q-input v-model="qry.val" label="Filter Text" dense >
        <template v-slot:append>
          <q-icon v-if="qry.val !== null" name="close" class="cursor-pointer" @click="qry.val = null" />
          <q-icon name="filter_list" @click="gotoPage(1)" />
        </template>
      </q-input>

      <q-input v-model="qry.field" label="Filter Field" class="q-mx-sm" dense >
      </q-input>



     <q-space></q-space>

    <q-pagination
      :value="currentPage"
      :max="numPages"
      :max-pages="5"
      :ellipses="false"
      :boundary-links="true"
      :boundary-numbers="false"
      :direction-links="true"
      @input="gotoPage"
    >
    </q-pagination>

  </q-toolbar>

  <table class="table table-striped table-sm full-width">
    <tr v-for="post in recs" :key="post._id">

    <td class="q-pt-xs text-no-wrap" valign="top">
      <div>
      {{ post.created_on | date  }}
      </div>
      <div class="text-xs">
      {{ post.posted_on | date  }}
      </div>
    </td>
    <td>
      <q-btn v-if="fbPageAccount" size=".9em" flat color="primary" @click="postToFb(post)">Share <q-icon size=".9em" name="fab fa-facebook-f"></q-icon></q-btn>
    </td>
    <td>
      <div class="text-sm">
        <router-link :to="{ name: 'adminPostView', params: { recId: post._id }}" class="text-primary">{{post.headline}}</router-link>
      </div>
      <div v-if="post.organization" class="text-xs">
        <router-link :to="{ name: 'adminOrgView', params: { recId: post.organization._id }}" class="text-primary">{{post.organization.name}}</router-link>
      </div>
    </td>
    <td>
      <div v-if="post.location">
        {{ post.location.city }},
        {{ post.location.state_code }},
      </div>
    </td>
    <td>
      <div v-if="post.user && post.user._id" class="text-sm">
        <router-link :to="{ name: 'adminUsersView', params: { recId: post.user._id }}" class="text-primary">{{post.user.first_name}} {{post.user.last_name}}</router-link>
      </div>
      <div v-else>
        Unknown
      </div>
    </td>

    <td class="text-right text-sm">
      <span v-if="post.order_info">
        {{post.order_info.total | currency}}
      </span>
    </td>

    <td class="text-right text-sm">
      <span v-if="post.order_info">
        <q-icon v-if="post.order_info.paid" name="fal fa-credit-card" color="primary"></q-icon>
      </span>
    </td>

    <td class="text-right text-sm">
      <q-icon v-if="post.shared_fb" name="check" color="green"></q-icon>
      <q-icon v-if="!post.shared_fb" name="close" color="red"></q-icon>
    </td>

    <td class="text-right text-sm">
      <q-icon v-if="post.active" title="Active" name="check" color="green" @click="fieldSet('active', !post.active, post._id)"></q-icon>
      <q-icon v-if="!post.active" title="Active" name="close" color="red" @click="fieldSet('active', !post.active, post._id)"></q-icon>
    </td>

    <td class="text-right text-sm">
      <q-icon v-if="post.posted" title="Posted" name="fas fa-file-check" color="green" @click="fieldSet('posted', !post.posted, post._id)"></q-icon>
      <q-icon v-if="!post.posted" title="Posted" name="fas fa-file-times" color="red" @click="fieldSet('posted', !post.posted, post._id)"></q-icon>
    </td>

    </tr>
  </table>

</div>
</template>

<script>
// import { AdminPostsActionTypes } from "../../../store/module-admin/module-admin-post/action-types";
import { adminMixin } from '../admin.mixin';
import { mapState, mapGetters } from 'vuex';
import { debounce } from "lodash";
import { notifyError, notifySuccess } from 'src/services/utils';
// import ApiService from '../../../services/api.service';

import adminPostsModule from "src/modules/admin/store/module-admin-post";
import storeRecListMixin from 'src/mixins/store_rec_list.mixin';

export default {
  mixins: [
    adminMixin,
    storeRecListMixin,
  ],
  data() {
    return {
      collection: 'posts',
      vuexCollection: 'posts.recs',
      vuexPath: 'admin/posts',
      vuexPageRequestAction: adminPostsModule.actionTypes.LoadRecordsPageRequest,


      fbSelect: null,
      fbAcctIdx: null,
      accessToken: null,
      fbOpts: {
        path: '/me',
        method: 'get',
        params: null
      },
      fbPageAccount: null,
      fbAccounts: [],
      fb: {
        accessToken: null,
        userId: null,
      },
      current: 1,
      filterField: 'any',
      filterVal: null,
      // qry: {
      // },
    }
  },
  computed: {
      // ...mapState('admin/posts', {
      //   loading: 'loading',
      //   page_size: 'page_size',
      //   // storeQry: 'qry',
      //   numPosts: 'qry_total',
      // }),
      // ...mapGetters('admin/posts', [
      //   'hasPageSet',
      // ]),
      // currentPage() {
      //   return +this.$route.params.pageId;
      // },
      // numTotalPosts() {
      //   return this.$store.getters['admin/posts/totalRecords'];
      // },
      // numPages() {
      //   return Math.ceil(this.numPosts / this.page_size);
      // },
      // posts() {
      //   return this.$store.getters['admin/posts/getPageSet'](this.currentPage);
      // },
  },

  watch: {
    // call again the method if the route changes
    // '$route': function() { this.reqData(); },
    'qry.val': {
      handler: debounce( function() {
            this.gotoPage(1);
        }, 500),
    },
    // 'filterVal': {
    //   handler: debounce( function(newValue) {
    //       // if (this.filterField) {
    //       //   this.qry[ this.filterField ] = newValue;
    //       //   this.gotoPage(1);
    //       // } else {
    //       //   this.filterField = 'any';
    //       // }
    //     }, 3000),
    //   deep: true,
    // },
  },

  created () {
    // this.qry = JSON.parse(JSON.stringify(this.storeQry));

    if (! this.qry ) {
      console.log('missing this.qry');
      this.qry = {
        field: null,
        val: null
      }
    } else {
      console.log('local qry is ', this.qry);
    }

    // this.reqData();
    // this.$store.dispatch( 'admin/posts/' + AdminPostsActionTypes.AdminPostsPreloadPageRecordsRequest, { pageId: this.$route.params.pageId } );

    this.$fb.getLoginStatus( (resp) => {
      if (resp.status === 'connected') {
        this.fb = resp.authResponse;
        this.accessToken = this.fb.accessToken;
        console.log('CONNECTED');
        this.fbGetAccounts();
      }
    });

  },

  methods: {

    fbGetAccounts() {
         this.$fb.api('/me/accounts', 'get', null, (res2) => {
          console.log('accounts result is ', res2);
          this.fbAccounts = res2.data;
        });
    },
    fbLogout() {
      this.$fb.logout( () => {
        this.accessToken = null;
      });
    },
    fbLogin() {
      this.$fb.login( (res) => {
        this.fb = res.authResponse;
        this.accessToken = this.fb.accessToken;
        this.fbGetAccounts();
      }, {scope: 'manage_pages,publish_pages'});
    },
    showAccount() {
      console.log('Account list is', this.fbAccounts);
      console.log('Account is', this.fbPageAccount);
      console.log('User is', this.fb);
    },
    // loadAllPostData() {
    //   this.posts.forEach( post => {
    //     this.$store.dispatch(`admin/posts/${AdminPostsActionTypes.AdminPostsGetRecordRequest}`, { postId: post._id });
    //   });
    // },
    async postToFb(post) {
      console.log('POST IS ', post);

      // try {
      //   const apiData = await ApiService.post('https://graph.facebook.com/v5.0/' + this.fbPageAccount.id + '/feed', {
      //     access_token: this.fb.accessToken,
      //     message: 'This is my api test message for ' + post.headline,
      //     });

      //   console.log('got back ', apiData);

      // } catch (err) {
      //   console.log('cautght err', err);

      //   if (err.response) {
      //     console.log('caught err', err.response);
      //   } else {
      //     console.log('Error: ', err.message);
      //     throw err;
      //   }

      //   return false;
      // }

      this.$fb.api('/' + this.fbPageAccount.id + '/feed', 'post', {
        access_token: this.fbPageAccount.access_token,
        message: ' Demo to share post for ' + post.headline,

      }, (result) => {
        console.log('API POST result ', result);
        notifySuccess('Shared post on facebook - ' + post.headline);
      });
    },
    getFBInfo() {
      this.$fb.api(this.fbOpts.path, this.fbOpts.method, this.fbOpts.params, (result) => {
        console.log('FB RESULT is ', result);
      })

    },
  },
}
</script>
