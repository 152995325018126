<template>
  <div>
    <h6>Home</h6>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('admin/posts', {
      loading: 'loading',
    }),
  },
}
</script>

