<template>
<div>
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="Admin" to="/admin/home" />
      <q-breadcrumbs-el label="JS Errors" @click="reqData(true)">
        <q-icon color="red" name="refresh"></q-icon>
      </q-breadcrumbs-el>
    </q-breadcrumbs>

  <div class="row justify-between items-end q-my-sm">
    <div class="col full-height text-h6">

    </div>


  </div>

  <q-toolbar class="bg-blue-2" style="min-height: 40px;">
      <q-toolbar-title shrink class="q-pr-xl">
        Total JS Errors
        {{numRecs}}
      <span v-if="numRecs != numTotalRecs">
        of {{numTotalRecs}}
      </span>
      </q-toolbar-title>

      <q-input v-model="qry.val" label="Filter Text" dense >
        <template v-slot:append>
          <q-icon v-if="qry.val !== null" name="close" class="cursor-pointer" @click="qry.val = null" />
          <q-icon name="filter_list" @click="gotoPage(1)" />
        </template>
      </q-input>

      <q-input v-model="qry.field" label="Filter Field" class="q-mx-sm" dense >
      </q-input>

     <q-space></q-space>

    <q-pagination
      :value="currentPage"
      :max="numPages"
      :max-pages="5"
      :ellipses="false"
      :boundary-links="true"
      :boundary-numbers="false"
      :direction-links="true"
      @input="gotoPage"
    >
    </q-pagination>

  </q-toolbar>

  <table class="table table-striped table-sm full-width">
    <tr v-for="sError in recs" :key="sError._id">

    <td class="q-pt-xs text-no-wrap" valign="top">
      {{ sError.error_time | date('YYYY-MM-DD HH:mm') }}
    </td>

    <td class="q-pt-xs text-no-wrap" valign="top">
      {{ sError.type }}
    </td>

    <td>
        <router-link v-if="sError._id" :to="{ name: 'adminJSErrorsView', params: { recId: sError._id }}" class="text-primary">{{sError.url || 'No URL'}}</router-link>
        <div class="text-caption">
        </div>
    </td>

    <td>
      <user-name :user="sError.jwt" show-email></user-name>
    </td>

    <td class="text-right text-sm">
    </td>

    <td class="text-right text-sm">
      <q-icon v-if="sError.reviewed" name="fal fa-envelope-open" color="primary" @click="fieldSet('reviewed', !sError.reviewed, sError._id)"></q-icon>
      <q-icon v-if="!sError.reviewed" name="fas fa-envelope" color="red" @click="fieldSet('reviewed', !sError.reviewed, sError._id)"></q-icon>
    </td>

    <td class="text-right text-sm">
      <q-icon v-if="sError.handled" name="fal fa-check-square" color="primary" @click="fieldSet('handled', !sError.handled, sError._id)"></q-icon>
      <q-icon v-if="!sError.handled" name="fal fa-square" color="red" @click="fieldSet('handled', !sError.handled, sError._id)"></q-icon>
    </td>

    </tr>
  </table>

</div>
</template>

<script>
import { mapState } from 'vuex';
import { debounce } from "lodash";
import { adminMixin } from '../admin.mixin';

import adminJSErrorsModule from "src/modules/admin/store/module-admin-js-error";
import storeRecListMixin from 'src/mixins/store_rec_list.mixin';


export default {
  mixins: [
    adminMixin,
    storeRecListMixin,
  ],
  data() {
    return {
      collection: 'js_errors',
      vuexCollection: 'js_errors.recs',
      vuexPath: 'admin/js_errors',
      vuexPageRequestAction: adminJSErrorsModule.actionTypes.LoadRecordsPageRequest,


      current: 1,
      filter: {
        qry: null
      },
    }
  },

  computed: {
      ...mapState('admin/js_errors', {
        loading: 'loading',
        loaded: 'loaded',
      }),
  },

  watch: {

  },

  created () {

  },

  methods: {

  },
}
</script>
