<template>
<div>
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="Admin" to="/admin/home" />
      <q-breadcrumbs-el label="Coupons" to="/admin/coupons/list/1" />
      <q-breadcrumbs-el label="Add" />
    </q-breadcrumbs>

<div class="text-uppercase text-h5 q-mt-md">
  Add Coupon
</div>


<q-form ref="addCouponForm" @submit="onSubmit">
    <div class="row">
      <div class="col-12 col-md-6 q-px-sm">
        <q-input
          v-model="coupon.name"
          label="Name"
          dense
          :rules="[
            val => !!val || 'Field is required',
            val => getServerError('name'),
          ]"
          @blur="clearServerError('name')"
        ></q-input>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6 q-px-sm">
        <q-input
          v-model="coupon.code"
          label="Code"
          dense
          :rules="[
            val => !!val || 'Field is required',
            val => getServerError('code'),
          ]"
          @blur="clearServerError('code')"
        ></q-input>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6 q-px-sm">
        <q-input
          ref="amount"
          v-model.number="coupon.amount"
          :prefix="amountPrefixChar"
          :suffix="amountSuffixChar"
          label="Amount"
          type="number"
          step="0.05"
          dense
          :rules="[
            val => !!val || 'Field is required',
            val => getServerError('amount'),
          ]"
          @blur="clearServerError('amount')"
        >
          <template v-slot:prepend>
            <q-icon v-show="coupon.amount_type == 'dollars'" name="fal fa-dollar-sign" class="cursor-pointer" />
            <q-icon v-show="coupon.amount_type == 'percent'" name="fal fa-percent" class="cursor-pointer" />
          </template>

          <template v-slot:append>
          <q-chip square dense clickable @click="toggleAmountType()">
            <q-avatar v-show="coupon.amount_type != 'dollars'" color="primary" text-color="white" icon="fal fa-dollar-sign" />
            <q-avatar v-show="coupon.amount_type == 'dollars'" color="primary" text-color="white" icon="fal fa-percent" />
            Toggle Type
          </q-chip>
          </template>
        </q-input>
      </div>
    </div>

    <div class="row">
      <div class="col-6 col-md-3 q-px-sm">
        <q-input
          v-model="coupon.use_count"
          label="Use Count"
          type="number"
          dense
          :rules="[
            val => getServerError('use_count'),
          ]"
          @blur="clearServerError('use_count')"
        ></q-input>
      </div>

      <div class="col-6 col-md-3 q-px-sm">
        <q-input
          v-model="coupon.use_limit"
          label="Limit"
          type="number"
          dense
          :rules="[
            val => getServerError('use_limit'),
          ]"
          @blur="clearServerError('use_limit')"
        ></q-input>
      </div>
    </div>



    <div class=" row">
        <div class="col-12 col-md-6 q-px-sm">
          <q-input
            v-model="coupon.description"
            label="Description"
            type="textarea"
            dense
            min-height="5rem"
            :rules="[
              val => getServerError('description'),
            ]"
            @blur="clearServerError('description')"></q-input>
        </div>
    </div>


    <div class=" row">
        <div class="col-12 col-md-6 q-px-sm" style="max-width: 300px">
            <q-input v-model="coupon.expires_on">
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                    <q-date v-model="coupon.expires_on" mask="YYYY-MM-DDTHH:mm:ss.SSS[Z]"  @input="() => $refs.qDateProxy.hide()" />
                  </q-popup-proxy>
                </q-icon>
                <q-icon name="access_time" class="cursor-pointer">
                  <q-popup-proxy ref="qTimeProxy" transition-show="scale" transition-hide="scale">
                    <q-time v-model="coupon.expires_on" mask="YYYY-MM-DDTHH:mm:ss.SSS[Z]"  @input="() => $refs.qTimeProxy.hide()" />
                  </q-popup-proxy>
                </q-icon>
                <q-icon name="close" class="cursor-pointer" @click="coupon.expires_on = null">
                </q-icon>
              </template>
            </q-input>
        </div>
    </div>

      <div class="row">
      <div class="col-12 col-md-6 q-px-sm q-py-md">
        <q-toggle
          v-model="coupon.reuse"
          label="Reuse"
          :rules="[
            val => getServerError('reuse'),
          ]"
          @blur="clearServerError('reuse')"
        ></q-toggle>

        <q-toggle
          v-model="coupon.active"
          label="Active"
          :rules="[
            val => getServerError('active'),
          ]"
          @blur="clearServerError('active')"
        ></q-toggle>
      </div>
    </div>



    <div class="row">
      <div class="col-12 q-pa-sm">
        <q-btn type="submit" color="primary" size="md" no-caps :loading="saving">Save Coupon</q-btn>
      </div>
    </div>

</q-form>


  <div class="row q-my-xl">
      <div class="col-12 q-px-sm">
        <show-data :data="coupon"></show-data>
      </div>
  </div>


</div>
</template>

<script>
import { mapState } from 'vuex';
import { date } from 'quasar';
import { serverErrorMixin } from "src/mixins/server_error.mixin";
import ApiService from "src/services/api.service";
import { adminMixin } from '../admin.mixin';

export default {
  mixins: [
    adminMixin,
    serverErrorMixin,
  ],
  data() {
    let defDate = new Date();
    defDate.setHours(0,0,0,0);
    defDate = date.addToDate(defDate, { days: 14 }).toISOString();

    return {
      saving: false,
      amountPrefixChar: null,
      amountSuffixChar: '%',
      coupon: {
        amount_type: 'percent',
        expires_on: defDate, // '2017-12-31T21:54:03Z',
        // expires_on: '2016-09-10THH:MM:SS[Z]',

      },
      amountTypes: [
        'percent', 'dollars'
      ],
      collection: 'coupons',
      recId: this.$route.params.couponId,
    };
  },
  computed: {
    ...mapState('admin/coupons', {
        loading: 'loading',
      }),
  },

  methods: {
    refreshRec() {
      // this.$store.dispatch(`admin/${AdminActionTypes.AdminGetUserRecordRequest}`, { userId: this.user._id });
    },

    toggleAmountType() {
      if (this.coupon.amount_type != 'dollars') {
        this.amountSuffixChar = null;
        this.amountPrefixChar = '$';
        this.coupon.amount_type = 'dollars';
      } else {
        console.log('running percent');
        this.amountSuffixChar = '%';
        this.amountPrefixChar = null;
        this.coupon.amount_type = 'percent';
      }
    },

    async onSubmit() {
      console.log("submit called", this.org);

      this.saving = true;

      try {
        this.saving = true;
        const apiData = await ApiService.post("/api/admin/coupons/save", this.coupon);
        console.log("in coupon save", apiData);

        if (apiData.data) {
          // dispatch
          // this.$store.dispatch(`auth/${AuthActionTypes.AuthLoginSuccess}`, apiData.data);
        }

        this.$emit("saved", apiData.data);
        this.$router.push({name: 'adminCouponView', params: {couponId: apiData.data._id}})

        return true;
      } catch (err) {
        this.saving = false;
        console.log('ON TOP', err);
        if (err.response) {
          console.log("caught orgSubmit err", err.response);

          this.serverErrorMessage = err.response.data.message;

          if (err.response.data.errors) {
            this.serverErrors = err.response.data.errors;
            console.log("serverErrors is now", this.serverErrors);
          } else {
            console.log('no serverErrors');
          }
          this.$refs.orgForm.validate();
        } else {
          console.log("Error: ", err.message);
          throw err;
        }

        return false;
      } finally {
        this.saving = false;
      }
    },

  }

}
</script>
