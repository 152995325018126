<template>
  <div>
     <q-toolbar class="bg-grey-4" style="min-height: 40px;">
      <q-toolbar-title shrink class="q-pr-xl">
        Marketing
      </q-toolbar-title>

      <q-btn :to="{name: 'adminMarketingHome'}" size="md" no-caps flat >
        Home
      </q-btn>

      <q-btn  :to="{name: 'adminMarketingNewsletterHome', params: { pageId: 1 }}"  size="md" no-caps flat >
        Newsletter
      </q-btn>

      <q-btn  :to="{name: 'adminMarketingCampaignsList', params: { pageId: 1 }}"  size="md" no-caps flat >
        Campaigns
      </q-btn>

      <q-btn  :to="{name: 'adminMarketingEmailsList', params: { pageId: 1 }}"  size="md" no-caps flat >
        Emails
      </q-btn>

      <q-space></q-space>

    </q-toolbar>

    <q-linear-progress v-if="loading" color="secondary" indeterminate />

    <router-view />
  </div>
</template>
<style scoped>
.v-progress-linear {
  margin-top: 0;
}
</style>
<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('post', {
      loading: 'loading',
    }),
  },
}
</script>

