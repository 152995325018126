<template>
<div>
    <q-linear-progress v-if="loading" color="secondary" indeterminate />

    <q-breadcrumbs class='q-ma-sm'>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="Admin" to="/admin/home" />
      <q-breadcrumbs-el label="Marketing" to="/admin/marketing" />
      <q-breadcrumbs-el label="Emails" @click="reqData(true)">
        &nbsp; <q-icon color="red" name="refresh"></q-icon>
      </q-breadcrumbs-el>
      <q-breadcrumbs-el>
        <q-btn flat dense size="xs" @click="$router.push({name: 'adminMarketingEmailsAdd'})">
          <q-icon color="red" name="add"></q-icon>
        </q-btn>
      </q-breadcrumbs-el>

    </q-breadcrumbs>

  <div class="row justify-between items-end q-my-sm">
    <div class="col full-height q-gutter-md">

  <q-btn size="sm" color="primary" icon-right="check" @click="selectAll">Select All</q-btn>
  <q-btn size="sm" color="red" icon-right="close" @click="ids = []">DeSelect All</q-btn>

  <q-btn size="sm" color="red" icon-right="close" @click="doImport">Import Emails</q-btn>

  <q-btn size="sm" color="red" align="between" icon-right="book" @click="markReviewed">Review Selected</q-btn>

  <q-btn size="sm" color="red" align="between" icon-right="block" @click="delDialog = true">Delete Selected &nbsp;&nbsp;</q-btn>

  <q-toggle v-model="showDelete">Enable Delete</q-toggle>

    </div>
  </div>

Recs:
  {{recs && recs.length || 'No recs'}}

  <q-toolbar class="bg-blue-2" style="min-height: 40px;">
      <q-toolbar-title shrink class="q-pr-xl">
        Total Email Recs
        {{numRecs}}
      <span v-if="numRecs != numTotalRecs">
        of {{numTotalRecs}}
      </span>
      </q-toolbar-title>

      <q-input v-model="qry.val" label="Filter Text" dense >
        <template v-slot:append>
          <q-icon v-if="qry.val !== null" name="close" class="cursor-pointer" @click="qry.val = null" />
          <q-icon name="filter_list" @click="gotoPage(1)" />
        </template>
      </q-input>

      <q-input v-model="qry.field" label="Filter Field" class="q-mx-sm" dense >
      </q-input>

     <q-space></q-space>

    <q-pagination
      :value="currentPage"
      :max="numPages"
      :max-pages="5"
      :ellipses="false"
      :boundary-links="true"
      :boundary-numbers="false"
      :direction-links="true"
      @input="gotoPage"
    >
    </q-pagination>

  </q-toolbar>

  <table class="table table-striped table-sm full-width">
    <tr>
      <th></th>
      <th></th>
      <th></th>
      <th>Created</th>
      <th></th>
      <th>Email</th>
      <th>Name</th>
      <th>Tags</th>

      <th title="Emailed">E</th>
      <th title="Viewed">V</th>
      <th title="Clicked">C</th>
      <th title="Bounced">B</th>
      <th title="UnSubscribed">U</th>
    </tr>

    <tr v-for="email in recs" :key="email._id">

      <td class="q-pt-xs text-no-wrap" valign="top" style="width: 30px;">
        <q-checkbox v-model="ids" :val="email._id" dense color="green" />
      </td>

              <td style="width: 26px;">
                <router-link :to="{name: 'adminMarketingEmailsView', params: {emailId: email._id}}"><i class="fa fa-file"></i></router-link>
              </td>
              <td style="width: 26px;">
                <router-link :to="{name: 'adminUsersView', params: {userId: email.user_id || 0}}"><i class="fa fa-user"></i></router-link>
              </td>
              <td style="width: 150px;">{{email['created_on'] | date('MMM D, YYYY hh:mma') }}</td>

              <td style="width: 26px;">
                <q-icon v-if="showDelete" title="delete" name="fas fa-trash-alt" color="negative" @click="deleteEmails([email._id])"></q-icon>
              </td>

              <td class="ellipsis" style="width: 180px;">
                  <router-link :to="{name: 'adminUsersView', params: {userId: email._id || 0}}">{{email.email}}</router-link>
              </td>
              <td style="width: 180px;">
                <router-link :to="{name: 'adminMarketingEmailsView', params: {emailId: email._id}}">{{email.first_name}} {{email.last_name}}</router-link>
              </td>

      <td class="text-sm">
        <q-chip v-for="tag in email.tags" :key="tag" dense square class="bg-positive text-white">
          {{tag}}
        </q-chip>
      </td>


      <td class="text-right text-sm" style="width: 25px;">
        <q-icon v-if="email.emailed" title="emailed" name="fal fa-check-square" color="positive" @click="fieldSet('emailed', !email.emailed, email._id)"></q-icon>
        <q-icon v-if="!email.emailed" title="not emailed" name="fal fa-square" color="red" @click="fieldSet('emailed', !email.emailed, email._id)"></q-icon>
      </td>

      <td class="text-right text-sm" style="width: 25px;">
        <q-icon v-if="email.viewed" title="viewed" name="fal fa-check-square" color="positive" @click="fieldSet('viewed', !email.viewed, email._id)"></q-icon>
        <q-icon v-if="!email.viewed" title="not viewed" name="fal fa-square" color="red" @click="fieldSet('viewed', !email.viewed, email._id)"></q-icon>
      </td>

      <td class="text-right text-sm" style="width: 25px;">
        <q-icon v-if="email.clicked" title="clicked" name="fal fa-check-square" color="positive" @click="fieldSet('clicked', !email.clicked, email._id)"></q-icon>
        <q-icon v-if="!email.clicked" title="not clicked" name="fal fa-square" color="red" @click="fieldSet('clicked', !email.clicked, email._id)"></q-icon>
      </td>

      <td class="text-right text-sm" style="width: 25px;">
        <q-icon v-if="email.bounced" title="bounced" name="fal fa-check-square" color="positive" @click="fieldSet('bounced', !email.bounced, email._id)"></q-icon>
        <q-icon v-if="!email.bounced" title="not bounced" name="fal fa-square" color="red" @click="fieldSet('bounced', !email.bounced, email._id)"></q-icon>
      </td>

      <td class="text-right text-sm" style="width: 25px;">
        <q-icon v-if="email.unsubscribed" title="unsubscribed" name="fal fa-check-square" color="positive" @click="fieldSet('unsubscribed', !email.unsubscribed, email._id)"></q-icon>
        <q-icon v-if="!email.unsubscribed" title="not unsubscribed" name="fal fa-square" color="red" @click="fieldSet('unsubscribed', !email.unsubscribed, email._id)"></q-icon>
      </td>

    </tr>
  </table>

  <q-dialog v-model="delDialog" position="bottom">
      <q-card style="width: 350px">
        <q-card-section class="row items-center no-wrap q-gutter-sm">
          <div>
            <div class="text-weight-bold">Confirm delete {{ids.length}} items</div>
          </div>

          <q-space />

          <q-btn color="grey" icon="cancel" @click="delDialog = false" />
          <q-btn color="negative" icon="check" @click="deleteEmails(ids)" />
        </q-card-section>
      </q-card>
    </q-dialog>

</div>
</template>

<style scoped>
tr:hover {
  background-color: #BBDEFB;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
// import { debounce } from "lodash";
import { adminMixin } from '../../admin.mixin';
import { notifyError, notifySuccess } from 'src/services/utils';
import { ApiService } from 'src/services/api.service';

// import { AdminMarketingEmailsActionTypes } from "../../../../store/module-admin/module-admin-marketing/module-admin-marketing-emails/action-types";

import adminMarketingEmailsModule from "src/modules/admin/store/module-admin-marketing/module-admin-marketing-emails";
import storeRecListMixin from 'src/mixins/store_rec_list.mixin';

export default {
  mixins: [
    adminMixin,
    storeRecListMixin,
  ],
  data() {
    return {
      showDelete: false,
      delDialog: false,
      current: 1,
      expandId: null,
      ids: [],

      collection: 'marketing_emails',
      vuexCollection: 'marketing.emails.recs',
      vuexPath: 'admin/marketing/emails',
      vuexPageRequestAction: adminMarketingEmailsModule.actionTypes.LoadRecordsPageRequest,

    }
  },

  computed: {
      ...mapState('admin/marketing/emails', {
        loading: 'loading',
        numPages: 'num_pages',
        numRecs: 'total_recs',
      }),
      // ...mapGetters('admin/marketing/emails', [
      //   'hasPageSet',
      // ]),
      // currentPage() {
      //   return +this.$route.params.pageId;
      // },
      // recs() {
      //   return this.$store.getters['admin/marketing/emails/getPageSet'](this.currentPage);
      // },
  },

  watch: {
  },

  created () {
    this.reqData();
  },

  methods: {
    // gotoPage (pageId) {
    //   console.log('got gotoPage ', pageId);
    //   try {
    //     this.$store.dispatch( 'admin/marketing/emails/' + AdminMarketingEmailsActionTypes.AdminMarketingEmailsLoadRecordsPageRequest, { page_id: +pageId } );

    //     this.$router.push({ name: 'adminMarketingEmailsList', params: { pageId: pageId }});
    //   } catch (err) {
    //     console.log('caught', err);
    //   }
    // },
    // reqData (force) {
    //   try {
    //     if (! this.hasPageSet(this.$route.params.pageId) || force) {
    //       this.$store.dispatch( 'admin/marketing/emails/' + AdminMarketingEmailsActionTypes.AdminMarketingEmailsLoadRecordsPageRequest, { page_id: this.$route.params.pageId } );
    //     }
    //   } catch (err) {
    //     console.log('caught', err);
    //   }
    // },

    selectAll() {
      this.ids = this.recs.map( x => x._id );
    },
    importEmail(emailId) {
      this.$store.dispatch( 'admin/marketing/emails/' + AdminMarketingEmailsActionTypes.AdminMarketingEmailRecordsImportRequest, { ids: [ emailId ]} );
    },
    doImport() {
      this.$store.dispatch( 'admin/marketing/emails/' + AdminMarketingEmailsActionTypes.AdminMarketingEmailRecordsImportRequest, { ids: this.ids } );
    },
    async deleteOneEmail(recId) {
      this.deleteEmails([recId]);
    },
    async deleteEmails(recs) {
      console.log('do invite of ', recs);

      this.inviting = true;

      try {
        const apiData = await ApiService.post("/api/admin/marketing/emails/delete", {recs});
        console.log('got back from invite ', apiData.data);

        this.$store.dispatch( 'admin/marketing/' + AdminMarketingEmailsActionTypes.AdminMarketingEmailsDeleteRecordsSuccess, { ids: apiData.data.ids } );

        notifySuccess('Successfully invited users');
        return true;
      } catch (err) {
        this.testing = false;

        if (err.response) {
          notifyError('Error getting rendered email ', err);
        } else {
          console.log("Error: ", err);
          throw err;
        }

        return false;
      } finally {
        this.inviting = false;
      }


    },
    markReviewed() {
      this.$store.dispatch( 'admin/marketing/emails/' + AdminMarketingEmailsActionTypes.AdminMarketingSetEmailRecordsReviewedRequest, { ids: this.ids } );
    },

  },
}
</script>
