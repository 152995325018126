<template>
<div>
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="Admin" />
    </q-breadcrumbs>

<div class="text-uppercase text-h3 q-mt-md">
  Admin Home
</div>

<div>
  <router-link :to="{name: 'adminStats'}">Stats</router-link>
</div>

</div>

</template>

<script>
export default {
}
</script>
