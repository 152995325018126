<template>
<div>
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="Admin" to="/admin/home" />
      <q-breadcrumbs-el label="Server Errors" :to="{name: 'adminServerErrorsList', params: { pageId: 1 }}" />
      <q-breadcrumbs-el :label="$route.params.recId" />
      <q-breadcrumbs-el label="View">
        <q-btn dense flat color="red" size="sm" icon="refresh" @click="refreshRec(true)" />
      </q-breadcrumbs-el>
    </q-breadcrumbs>

<div v-if="rec">
<div class="row q-my-sm" >
  <div class="col-12 text-h5">
    {{rec.exception}} - {{rec.error}}
  </div>

  <div class="col-12">

    <q-chip square dense clickable @click="fieldSet('handled', !rec.handled)" >
      <q-avatar v-show="rec.handled" color="green" text-color="white" icon="check" />
      <q-avatar v-show="!rec.handled" color="red" text-color="white" icon="close" />
      <span v-show="!rec.handled">Not</span>&nbsp;
      Handled
    </q-chip>

  </div>

</div>

<div class="row q-my-xl">
  <table class="table table-striped table-sm full-width">

    <tr>
      <th style="width: 180px;" class="text-left">Exception Type</th>
      <td>
          {{ rec.exception }}
      </td>
    </tr>

    <tr>
      <th style="width: 180px;" class="text-left">Error</th>
      <td>
          {{ rec.error }}
      </td>
    </tr>

    <tr>
      <th style="width: 180px;" class="text-left">URL</th>
      <td>
          {{rec.method}} {{ rec.url }}
      </td>
    </tr>

    <tr>
      <th style="width: 180px;" class="text-left">IP</th>
      <td>
          {{ rec.ip }}
      </td>
    </tr>

    <tr>
      <th style="width: 180px;" class="text-left">User</th>
      <td>
        <user-name :user="rec.user" show-id show-email />
      </td>
    </tr>

    <tr>
      <th style="width: 180px;" class="text-left">Error Time</th>
      <td>
       {{rec.error_time | date('MMM D, YYYY hh:mma')}}
      </td>
    </tr>

    <tr>
      <th style="width: 180px;" class="text-left">Reviewed</th>
      <td>
          <q-chip square dense clickable class="q-my-none" @click="fieldSet('reviewed', !rec.reviewed)" >
            <q-avatar v-show="rec.reviewed" color="green" text-color="white" icon="check" />
            <q-avatar v-show="!rec.reviewed" color="red" text-color="white" icon="close" />
            <span v-show="!rec.reviewed">Not</span>&nbsp;
            Reviewed
          </q-chip>
      </td>
    </tr>


    <tr>
      <th style="width: 180px;" class="text-left">Handled</th>
      <td>
          <q-chip square dense clickable class="q-my-none" @click="fieldSet('handled', !rec.handled)" >
            <q-avatar v-show="rec.handled" color="green" text-color="white" icon="check" />
            <q-avatar v-show="!rec.handled" color="red" text-color="white" icon="close" />
            <span v-show="!rec.handled">Not</span>&nbsp;
            Handled
          </q-chip>
      </td>
    </tr>

    <tr>
      <th style="width: 180px;" class="text-left">Traceback</th>
      <td>
        <div style="white-space: pre-line">
          {{rec.traceback}}
        </div>
      </td>
    </tr>

  </table>
</div>

<div class="text-h4">Headers</div>
<div class="row q-mb-xl">
  <table class="table table-striped table-sm full-width">

    <tr v-for="(hv, hk) in rec.headers" :key="hk">
      <th style="min-width: 225px;" class="text-left">{{hk}}</th>
      <td><div class="full-width" style="overflow: hidden;">
        {{hv}}
      </div></td>
    </tr>
  </table>
</div>

<div class="text-h4">JSON</div>
<div class="row q-mb-xl">
  <table class="table table-striped table-sm full-width">

    <tr v-for="(hv, hk) in rec.json" :key="hk">
      <th style="min-width: 225px;" class="text-left">{{hk}}</th>
      <td><div class="full-width" style="overflow: hidden;">
        {{hv}}
      </div></td>
    </tr>
  </table>
</div>

<div class="text-h4">GET</div>
<div class="row q-mb-xl">
  <table class="table table-striped table-sm full-width">

    <tr v-for="(hv, hk) in rec.GET" :key="hk">
      <th style="min-width: 200px; width: 200px;" class="text-left">{{hk}}</th>
      <td><div class="full-width" style="overflow: hidden;">
        {{hv}}
      </div></td>
    </tr>
  </table>
</div>

<div class="text-h4">POST</div>
<div class="row q-mb-xl">
  <table class="table table-striped table-sm full-width">

    <tr v-for="(hv, hk) in rec.POST" :key="hk">
      <th style="min-width: 225px;" class="text-left">{{hk}}</th>
      <td><div class="full-width" style="overflow: hidden;">
        {{hv}}
      </div></td>
    </tr>
  </table>
</div>




<div class="text-h5">Management Tools</div>

</div>

  <div class="row q-my-xl">
      <div class="col-12 q-px-sm">
        <show-data :data="serverError"></show-data>
      </div>
  </div>


</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import storeRecViewMixin from 'src/mixins/store_rec_view.mixin';
import adminServerErrorsModule from "src/modules/admin/store/module-admin-server-error";
import { adminMixin } from '../admin.mixin';

export default {
  mixins: [
    adminMixin,
    storeRecViewMixin,
  ],
  data() {
    return {
      setField: null,
      setType: 'Str',
      setValue: null,
      collection: 'errors',
      vuexCollection: 'server_errors.recs',
      recId: this.$route.params.recId,

      vuexPath: 'admin/server_errors',
      vuexGetRecordAction: adminServerErrorsModule.actionTypes.GetRecordRequest

    };
  },
  computed: {
    ...mapState('admin', {
        serverErrorLoading: 'loading',
      }),
    ...mapGetters('admin/server_errors', [
      'getRecById',
    ])

  },
  created() {
    this.fieldSet('reviewed', true);
  },
  methods: {
  },

}
</script>
