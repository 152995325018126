<template>
<div>
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="Admin" to="/admin/home" />
      <q-breadcrumbs-el label="Posts" to="/admin/posts/list/1" />
      <q-breadcrumbs-el :label="$route.params.recId" />
      <q-breadcrumbs-el label="View">
        <q-btn dense flat color="red" size="sm" icon="refresh" @click="refreshRec(true)" />
      </q-breadcrumbs-el>
    </q-breadcrumbs>

<div v-if="rec" class="row q-mt-sm">
  <div class="col-12 text-h5">
    {{ rec.headline}}
  </div>
  <div v-if="rec.organization" class="col-12">
    <router-link :to="{ name: 'adminOrgView', params: {recId: rec.organization._id} }">
    {{rec.organization.name || '[ NO ORG NAME ]'}}
    </router-link>
  </div>
  <div class="col-12">
  </div>

  <div class="col-12">

    <q-chip square dense clickable @click="fieldSet('posted', !rec.posted)" >
      <q-avatar v-show="rec.posted" color="green" text-color="white" icon="check" />
      <q-avatar v-show="!rec.posted" color="red" text-color="white" icon="close" />
      Posted
    </q-chip>

    <q-chip square dense clickable @click="fieldSet('active', !rec.active)" >
      <q-avatar v-show="rec.active" color="green" text-color="white" icon="check" />
      <q-avatar v-show="!rec.active" color="red" text-color="white" icon="close" />
      Active
    </q-chip>
  </div>

  <div class="col-12">
    Created On: {{rec.created_on | date('MMM D, YYYY hh:mma')}}
    <q-chip clickable class="bg-red" square dense size="sm" @click="fieldSubDays('created_on', 7, rec._id)">
      <q-icon class="text-white" name="fast_rewind" />
    </q-chip>
    <q-chip clickable class="bg-red" square dense size="sm" @click="fieldSubDays('created_on', 1, rec._id)">
      <q-icon class="text-white" name="remove" />
    </q-chip>
    <q-chip clickable class="bg-green" square dense size="sm" @click="fieldAddDays('created_on', 1, rec._id)">
      <q-icon class="text-white" name="add" />
    </q-chip>
    <q-chip clickable class="bg-green" square dense size="sm" @click="fieldAddDays('created_on', 7, rec._id)">
      <q-icon class="text-white" name="fast_forward" />
    </q-chip>
  </div>

  <div class="col-12">
    Posted On: {{rec.posted_on | date('MMM D, YYYY hh:mma')}}
    <q-chip clickable class="bg-red" square dense size="sm" @click="fieldSubDays('posted_on', 7, rec._id)">
      <q-icon class="text-white" name="fast_rewind" />
    </q-chip>
    <q-chip clickable class="bg-red" square dense size="sm" @click="fieldSubDays('posted_on', 1, rec._id)">
      <q-icon class="text-white" name="remove" />
    </q-chip>
    <q-chip clickable class="bg-green" square dense size="sm" @click="fieldAddDays('posted_on', 1, rec._id)">
      <q-icon class="text-white" name="add" />
    </q-chip>
    <q-chip clickable class="bg-green" square dense size="sm" @click="fieldAddDays('posted_on', 7, rec._id)">
      <q-icon class="text-white" name="fast_forward" />
    </q-chip>
  </div>

</div>

<div class="row q-my-xl">
  <table class="table table-striped table-sm full-width">
    <tr>
      <th style="width: 180px;" class="text-left">User</th>
      <td>
        <span v-if="rec.user">
          <user-name :user="rec.user" :show-id="true" :show-email="true" />
        </span>
      </td>
    </tr>

    <tr>
      <th style="width: 180px;" class="text-left">Order</th>
      <td>
        <span v-if="rec.order_info">
          <router-link :to="{name: 'adminOrdersView', params: { orderId: rec.order_info._id} }">[ {{rec.order_info._id}} ]</router-link> &nbsp;
          {{ rec.order_info.total | currency }}

       <span v-if="rec.order_info.coupon">
         [ <router-link :to="{name: 'adminCouponView', params: { couponId: rec.order_info.coupon._id}}">{{rec.order_info.coupon.code}}</router-link> - {{rec.order_info.coupon.amount}} {{rec.order_info.coupon.amount_type}} ]
       </span>

          <q-chip class="q-mx-md q-my-none" square dense clickable  @click="markPaid(!rec.order_info.paid)">
            <q-avatar v-show="rec.order_info.paid" color="green" text-color="white" icon="check" />
            <q-avatar v-show="!rec.order_info.paid" color="red" text-color="white" icon="close" />
            <span v-show="!rec.order_info.paid" class="q-mr-xs">Not</span>
            Paid
          </q-chip>

        </span>
      </td>
    </tr>

    <tr>
      <th style="width: 180px;" class="text-left">Organization</th>
      <td>
        <span v-if="rec.organization">
          <router-link :to="{name: 'adminOrgView', params: { recId: rec.organization._id} }">[ {{rec.organization._id}} ]</router-link> &nbsp;
          <router-link :to="{name: 'adminOrgView', params: { recId: rec.organization._id} }">{{rec.organization.name}}</router-link>
        </span>
      </td>
    </tr>

  </table>
</div>

<div class="striped">
<div class="row q-pa-md">
   <div class="col-2 text-bold q-pa-md">
    Headline
  </div>
  <div class="col-grow">
    <q-input v-model="rec.headline" class="" dense @keyup.enter="fieldSet('headline', rec.headline, rec._id)">
        <template v-slot:after>
          <q-icon name="fas fa-cloud-upload" @click="fieldSet('headline', rec.headline, rec._id)" />
        </template>
    </q-input>
  </div>
</div>

<div class="row q-pa-sm">
   <div class="col-2 text-bold q-pa-md">
    Position
  </div>
  <div class="col-grow">
    <q-input v-model="rec.position" class="" dense @keyup.enter="fieldSet('position', rec.position, rec._id)">
        <template v-slot:after>
          <q-icon name="fas fa-cloud-upload" @click="fieldSet('position', rec.position, rec._id)" />
        </template>
    </q-input>
  </div>
</div>

<div class="row q-pa-sm">
   <div class="col-2 text-bold q-pa-md">
    Description
  </div>
  <div class="col-grow">
    <q-input v-model="rec.description" type="textarea" class="" dense>
        <template v-slot:after>
          <q-icon name="fas fa-cloud-upload" @click="fieldSet('description', rec.description, rec._id)" />
        </template>
    </q-input>
  </div>
</div>

<div class="row q-pa-sm">
   <div class="col-2 text-bold q-pa-md">
   Location
  </div>
  <div v-if="rec.location" class="col-grow">
    <div class="row">
      <div class="col-2 q-py-md">
        State
      </div>
      <div class="col">

        <div style="min-width: 200px; max-width: 350px;">
            <q-select v-model="rec.location.state_code" dense label="State" value="" :options="siteArrays.state_codes" @input="fieldSet('location.state_code', $event)">
            </q-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-2 q-py-md">
        Zip code
      </div>
      <div class="col">
        <q-input v-model="rec.location.zipcode" class="" dense @keyup.enter="fieldSet('location.zipcode', rec.location.zipcode, rec._id)">
            <template v-slot:after>
              <q-icon name="fas fa-cloud-upload" @click="fieldSet('location.zipcode', rec.location.zipcode, rec._id)" />
            </template>
        </q-input>
      </div>
    </div>
    <div class="row">
      <div class="col-2 q-py-md">
        City
      </div>
      <div class="col">
        <q-input v-model="rec.location.city" class="" dense @keyup.enter="fieldSet('location.city', rec.location.city, rec._id)">
            <template v-slot:after>
              <q-icon name="fas fa-cloud-upload" @click="fieldSet('location.city', rec.location.city, rec._id)" />
            </template>
        </q-input>
      </div>
    </div>
    <div class="row">
      <div class="col-2 q-py-md">
        Address
      </div>
      <div class="col">
        <q-input v-model="rec.location.address" class="" dense @keyup.enter="fieldSet('location.address', rec.location.address, rec._id)">
            <template v-slot:after>
              <q-icon name="fas fa-cloud-upload" @click="fieldSet('location.address', rec.location.address, rec._id)" />
            </template>
        </q-input>
      </div>
    </div>

  </div>
  <div v-else class="q-py-md">
    <q-btn size="xs" color="red" icon-right="refresh" @click="refreshRec()" />
  </div>
</div>


<div class="row q-pa-sm">
   <div class="col-2 text-bold q-pa-md">
    Skills
  </div>
  <div class="col-grow">
      <div class="block">
      <q-chip v-for="skill in rec.skills" :key="skill"  square color="positive" text-color="white">
            Skill &nbsp; &nbsp;
            <q-chip size=".80em" removable @remove="fieldPull('skills', skill, rec._id)">{{skill}}&nbsp;&nbsp;</q-chip>
          </q-chip>
      </div>
      <div style="min-width: 200px; max-width: 350px;">
            <q-select dense label="Add Skill" value="" :options="siteArrays.skills" @input="fieldPush('skills', $event.value)">
            </q-select>
      </div>
  </div>
</div>

<div class="row q-pa-sm">
   <div class="col-2 text-bold q-pa-md">
    Org Types
  </div>
  <div class="col-grow">
      <div class="block">
      <q-chip v-for="orgType in rec.org_types" :key="orgType"  square color="positive" text-color="white">
            Org Type &nbsp; &nbsp;
            <q-chip size=".80em" removable @remove="fieldPull('org_types', orgType, rec._id)">{{orgType}}&nbsp;&nbsp;</q-chip>
          </q-chip>
      </div>
      <div style="min-width: 200px; max-width: 350px;">
            <q-select dense label="Add Org Type" value="" :options="siteArrays.org_types" @input="fieldPush('org_types', $event)">
            </q-select>
      </div>
  </div>
</div>

<div class="row q-pa-sm">
   <div class="col-2 text-bold q-pa-md">
    Benefits
  </div>
  <div class="col-grow">
      <div class="block">
      <q-chip v-for="benefit in rec.benefits" :key="benefit"  square color="positive" text-color="white">
            Benefits &nbsp; &nbsp;
            <q-chip size=".80em" removable @remove="fieldPull('benefits', benefit, rec._id)">{{benefit}}&nbsp;&nbsp;</q-chip>
          </q-chip>
      </div>
      <div style="min-width: 200px; max-width: 350px;">
            <q-select dense label="Add Benefit" value="" :options="siteArrays.benefits" @input="fieldPush('benefits', $event)">
            </q-select>
      </div>
  </div>
</div>

<div class="row q-pa-sm">
   <div class="col-2 text-bold q-pa-md">
    Degrees
  </div>
  <div class="col-grow">
      <div class="block">
      <q-chip v-for="degree in rec.degrees" :key="degree"  square color="positive" text-color="white">
            Degree &nbsp; &nbsp;
            <q-chip size=".80em" removable @remove="fieldPull('degrees', degree, rec._id)">{{degree}}&nbsp;&nbsp;</q-chip>
          </q-chip>
      </div>
      <div style="min-width: 200px; max-width: 350px;">
            <q-select dense label="Add Degree" value="" :options="siteArrays.degrees" @input="fieldPush('degrees', $event.value)">
            </q-select>
      </div>
  </div>
</div>

<div class="row q-pa-sm">
   <div class="col-2 text-bold q-pa-md">
   Apply
  </div>
  <div v-if="rec.apply" class="col-grow">
    <div class="row">
      <div class="col-2 q-py-md">
        Email
      </div>
      <div class="col">
        <q-input v-model="rec.apply.email" class="" dense @keyup.enter="fieldSet('apply.email', rec.apply.email, rec._id)">
            <template v-slot:after>
              <q-icon name="fas fa-cloud-upload" @click="fieldSet('apply.email', rec.apply.email, rec._id)" />
            </template>
        </q-input>
      </div>
    </div>

    <div class="row">
      <div class="col-2 q-py-md">
        URL
      </div>
      <div class="col">
        <q-input v-model="rec.apply.url" class="" dense @keyup.enter="fieldSet('apply.url', rec.apply.url, rec._id)">
            <template v-slot:after>
              <q-icon name="fas fa-cloud-upload" @click="fieldSet('apply.url', rec.apply.url, rec._id)" />
            </template>
        </q-input>
      </div>
    </div>
    <div class="row">
      <div class="col-2 q-py-md">
        Info
      </div>
      <div class="col-grow">
        <q-input v-model="rec.apply.info" type="textarea" dense>
            <template v-slot:after>
              <q-icon name="fas fa-cloud-upload" @click="fieldSet('apply.info', rec.apply.info, rec._id)" />
            </template>
        </q-input>
      </div>
    </div>

  </div>
  <div v-else class="q-py-md">
    <q-btn size="xs" color="red" icon-right="refresh" @click="refreshRec()" />
  </div>
</div>

<div class="row q-pa-sm">
   <div class="col-2 text-bold q-pa-md">
   Contact
  </div>
  <div v-if="rec.contact" class="col-grow">
    <div class="row">
      <div class="col-2 q-py-md">
        Email
      </div>
      <div class="col">
        <q-input v-model="rec.contact.email" class="" dense @keyup.enter="fieldSet('contact.email', rec.contact.email, rec._id)">
            <template v-slot:after>
              <q-icon name="fas fa-cloud-upload" @click="fieldSet('contact.email', rec.contact.email, rec._id)" />
            </template>
        </q-input>
      </div>
    </div>

    <div class="row">
      <div class="col-2 q-py-md">
        Name
      </div>
      <div class="col">
        <q-input v-model="rec.contact.name" class="" dense @keyup.enter="fieldSet('contact.name', rec.contact.name, rec._id)">
            <template v-slot:after>
              <q-icon name="fas fa-cloud-upload" @click="fieldSet('contact.name', rec.contact.name, rec._id)" />
            </template>
        </q-input>
      </div>
    </div>
  </div>
  <div v-else class="q-py-md">
    <q-btn size="xs" color="red" icon-right="refresh" @click="refreshRec()" />
  </div>
</div>


</div>

<q-toolbar class="bg-grey-3 text-white q-my-md shadow-2 row q-col-gutter-xs">
  <div class="col-3">
    <q-select v-model="setField" :options="recKeys.sort()" dense borderless class="" @input="setValue = getPathValue($event)" />
  </div>

  <div v-if="setType === 'Bool'" class="col-2">
    <q-toggle v-if="setType === 'Bool'" v-model="setValue" dense />
  </div>

  <div v-if="setType !== 'Bool'" class="col-3">
     <q-input
        v-if="setType !== 'Bool'"
        v-model="setValue"
        borderless
        dense
        class="q-pa-xs"
        :rules="[ val => val && val.length > 0 || 'Please type something']"
      >
    </q-input>
  </div>


  <div class="col-1">
    <q-select v-model="setType" :options="['Str', 'Bool', 'Number', 'JSON']" dense borderless />
  </div>

  <div class="col-2">
    <q-btn class="q-ma-md" size="sm" label="Set Value" color="secondary" @click="fieldSet(setField, setValue)"></q-btn>
  </div>

</q-toolbar>

<div class="row q-my-md">
  <h6 class="q-my-md">Management Tools</h6>

    <admin-choose-user
      :users="$store.state.admin.users || {}"
      :search-fields="['_id', 'first_name', 'last_name', 'email']"
      @update="setPostUser($event)"></admin-choose-user>

</div>

  <div class="row q-my-xl">
      <div class="col-12 q-px-sm">
        <show-data :data="rec"></show-data>
      </div>
  </div>


</div>
</template>

<script>
import { get as loadashGet } from 'lodash';
import * as DotObject from 'dot-object';
import { mapState, mapGetters } from 'vuex';
import { adminMixin } from '../admin.mixin';
import { storeRecViewMixin } from 'src/mixins/store_rec_view.mixin';
import adminPostsPendingsModule from "src/modules/admin/store/module-admin-pending-post";
import AdminChooseUser from '../../../components/admin-choose-user';

export default {
  components: {
    'AdminChooseUser': AdminChooseUser,
  },
  mixins: [
    adminMixin,
    storeRecViewMixin,
  ],
  data() {
    return {
      vuexCollection: 'pending_posts.recs',
      collection: 'posts_prep',

      recId: this.$route.params.recId,
      changeUser: null,
      userList: Object.values(this.$store.state.admin.users || {}),

      newSkill: null,
      newOrgType: null,
      newDegree: null,

      setField: null,
      setType: 'Str',
      setValue: null,

      vuexPath: 'admin/pending_posts',
      vuexGetRecordAction: adminPostsPendingsModule.actionTypes.GetRecordRequest,

    };
  },
  computed: {
    recKeys() {
      return this.rec ? Object.keys( DotObject.dot(this.rec) ) : [];
    },
    ...mapGetters('admin/pending_posts', [
      'getRecById',
    ]),
    ...mapState('site', {
      siteArrays: 'arrs',
    }),
  },
  methods: {
    updateValue(v, evt) {
      this[v] = evt.target.value;
    },
    markPaid(paid) {
      this.fieldSet('order_info.paid', paid, this.rec._id, 'posts_prep', 'pending_posts.posts');
      this.fieldSet('paid', paid, this.rec.order_info._id, 'orders', 'orders.orders');
    },
    getPathValue(path) {
      const val = loadashGet(this.user, path);
      console.log('getPathValue', path, val);
      if (val === true || val === false) {
        this.setType = 'Bool';
      } else {
        this.setType = 'Str';
      }

      return val;
    },

    setPostUser(user) {
      console.log('change user to ', user);
      this.fieldSet('user', {
        '_id': user._id,
        'first_name': user.first_name,
        'last_name': user.last_name,
        'email': user.email,
      } );

    },
  },
}
</script>
