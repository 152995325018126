<template>
<div>
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="Admin" to="/admin/home" />
      <q-breadcrumbs-el label="Marketing" to="/admin/marketing/home" />
      <q-breadcrumbs-el label="Campaigns" to="/admin/marketing/campaigns/list/1" />
      <q-breadcrumbs-el label="View">
        {{$route.params.recId}}
        <q-btn dense flat color="red" size="sm" icon="refresh" @click="refreshRec(true)" />
        <q-btn dense flat color="red" size="xs" icon="far fa-pencil" @click="$router.push({name: 'adminMarketingCampaignsEdit', params: {campaignId: $route.params.recId}})" />
      </q-breadcrumbs-el>
    </q-breadcrumbs>

    <div>
      {{rec}}
    </div>

<div v-if="rec" class="q-my-md">

  <div class="striped">
    <div class="row">
      <div class="col-2 text-bold q-pa-md">
        Name
      </div>
      <div class="col q-py-md">
          {{rec.name}}
      </div>
    </div>
    <div class="row">
      <div class="col-2 text-bold q-pa-md">
        Id
      </div>
      <div class="col q-py-md">
          {{rec._id}}
      </div>
    </div>
    <div class="row">
      <div class="col-2 text-bold q-pa-md">
        From
      </div>
      <div v-if="rec.from_info" class="col q-py-md">
          "{{rec.from_info.first_name}} {{rec.from_info.last_name}}"
          &lt;{{rec.from_info.email}}&gt;
      </div>
    </div>
    <div class="row">
      <div class="col-2 text-bold q-pa-md">
        Send On
      </div>
      <div v-if="rec.send_on" class="col q-py-md">
        {{rec.send_on | date('MMM D, YYYY HH:mm:ssa')}}
      </div>
    </div>
    <div class="row">
      <div class="col-2 text-bold q-pa-md">
        Subject
      </div>
      <div class="col q-py-md">
          {{rec.subject}}
      </div>
    </div>
    <div class="row">
      <div class="col-2 text-bold q-pa-md">
        Body
      </div>
      <div class="col q-py-md" style="white-space: pre-line;">
        <q-tabs
          v-model="bodyTab"
          dense
          class="text-grey"
          active-color="primary"
          indicator-color="primary"
          inline-label
          align="justify"
          narrow-indicator
        >
          <q-tab name="text" label="Text" />
          <q-tab name="email">
            Email <q-btn flat dense class="pointer-events-auto" size="sm" color="red" icon="refresh" @click.stop="getRenderedBody()" />
          </q-tab>
          <q-tab name="edit" label="Edit" />
        </q-tabs>

        <q-separator />

        <q-tab-panels v-model="bodyTab" animated>
          <q-tab-panel name="text">
            {{rec.html_body}}
          </q-tab-panel>

          <q-tab-panel name="email">
            <iframe class="full-width" style="height: 660px;" :srcdoc="renderedBody" frameborder="1">
            </iframe>
          </q-tab-panel>

          <q-tab-panel name="edit">
            <div class="q-my-md">
              <q-input v-model="rec.html_body" type="textarea" input-style="min-height: 400px;"></q-input>
            </div>

            <q-btn label="Save" color="primary" @click="fieldSet('html_body', rec.html_body, rec._id) && getRenderedBody()" />
          </q-tab-panel>
        </q-tab-panels>
      </div>
    </div>

    <div class="row">
      <div class="col-2 text-bold q-pa-md">
        Template Data
      </div>
      <div class="col q-ma-sm q-pa-sm bg-white">

        <div class="striped q-mb-sm">
        <div class="row">
           <div class="col-auto text-weight-bold" style="width: 200px;">
            Field
           </div>
           <div class="col text-weight-bold">
            Value
           </div>
        </div>

        <div v-for="field in Object.keys(rec.template_data || {})" :key="field" class="row q-py-xs">
          <div class="col-auto" style="width: 200px;">
            {{field}}
          </div>
          <div class="col">
            {{rec.template_data[field]}}
          </div>
        </div>
      </div>

        <div class="row">
          <div class="col-12 flex q-gutter-md">

            <q-input v-model="newTdField" label="Field" style="width: 100px;" dense outline ></q-input>

            <q-input v-model="newTdVal" label="Value" style="width: 300px;" dense outline ></q-input>

            <q-btn label="Add Template Data" color="secondary" @click="fieldSet('template_data.'+newTdField, newTdVal, rec._id)" />

          </div>
        </div>

      </div>
    </div>


    </div>

  </div>

<div class="text-h5 q-mt-xl">Tools</div>

<div class="row">
  <div class="col-2 text-bold q-pa-lg">
    Test
  </div>
  <div class="col q-py-md">
    <q-input v-model="testEmail" label="Test Email" style="width: 300px;" dense>
      <template v-slot:after>
        <q-btn round dense flat icon="send" @click="sendTest" />
      </template>
    </q-input>
  </div>
</div>

<div class="row">
  <div class="col-2 text-bold q-pa-lg">
    Send Emails
  </div>
  <div class="col q-py-md q-gutter-sm">
    <div class="row">
      <div class="col">
        <q-chip v-for="tag in tags" :key="tag" removable @remove="tags = tags.filter(x => x != tag)">{{tag}}</q-chip>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <q-input v-model="newTag" label="Tag" style="width: 300px;" dense outline filled>
          <template v-slot:append>
            <q-btn round dense flat icon="add" @click="addTag(newTag)" />
          </template>
        </q-input>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <q-btn dense no-caps color="secondary" @click="sendEmailsByTags">&nbsp;Send Emails By Tag&nbsp;</q-btn>
      </div>
    </div>

    <div class="row">
      <div class="col flex q-gutter-sm items-center">
        <q-input v-model.number="spamPauseDays" type="number" label="Skip Days" style="width: 50px;" dense outline ></q-input>
        <q-input v-model.number="spamPauseHours" type="number" label="Skip Hours" style="width: 50px;" dense outline ></q-input>

        <q-btn no-caps color="secondary" size="sm" icon-right="block" @click="clearPauseTime">Clear&nbsp;&nbsp;</q-btn>

      </div>
    </div>

  <div class="col-auto q-pa-md q-gutter-sm flex">
  </div>
  <div class="col q-py-md">
  </div>

  </div>


</div>





  <div class="row q-my-xl">
      <div class="col-12 q-px-sm">
        <show-data :data="campaign"></show-data>
      </div>
  </div>


</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { adminMixin } from '../../admin.mixin';
import { notifyError, notifySuccess } from 'src/services/utils';
import { ApiService } from 'src/services/api.service';

import storeRecViewMixin from 'src/mixins/store_rec_view.mixin';
import adminMarketingCampaignsModule from "src/modules/admin/store/module-admin-marketing/module-admin-marketing-campaigns"

export default {
  mixins: [
    adminMixin,
    storeRecViewMixin,
  ],
  data() {
    return {
      bodyTab: 'text',
      tags: ['cybermonday'],
      newTdField: null,
      newTdVal: null,
      newTag: null,
      renderedBody: null,
      fetchingBody: false,
      setField: null,
      setType: 'Str',
      setValue: null,

      collection: 'marketing_campaigns',
      vuexCollection: 'marketing.campaigns.recs',
      recId: this.$route.params.recId,

      vuexPath: 'admin/marketing/campaigns',
      vuexGetRecordAction: adminMarketingCampaignsModule.actionTypes.GetRecordReques,

      testEmail: this.$store.state.auth.user.email,
      testing: false,
      spamPauseDays: 1,
      spamPauseHours: 0,
    };
  },
  computed: {
    ...mapState('admin/marketing/campaigns', {
        'loading': 'loading',
        'loaded': 'loaded',
      }),
    ...mapGetters('admin/feedback', [
      'getRecById',
    ]),
  },
  created() {

    // this.fieldSet('reviewed', true);
  },
  methods: {
    openShowDialog() {
      console.log();
    },

    addTag(tag) {
      if (! this.tags.includes(tag)) {
        this.tags.push(tag);
      }
    },

    rmTag(tag) {
      this.tags = this.tags.filter(x => x !== tag);
    },

    clearPauseTime() {
      this.spamPauseDays = null;
      this.spamPauseHours = null;
    },

    async sendEmailsByTags() {
      console.log('sendEmailsByTags');

      this.testing = true;

      try {
        this.testing = true;
        const apiData = await ApiService.post("/api/admin/marketing/campaigns/send_by_tags", {
          campaign_id: this.$route.params.campaignId,
          tags: this.tags,
          days: this.spamPauseDays,
          hours: this.spamPauseHours,
        });

        notifySuccess('Sent test email to ' + apiData.data.count + ' emails');
        return true;

      } catch (err) {
        this.testing = false;

        if (err.response) {
          notifyError('Error sending test email ', err);
        } else {
          console.log("Error: ", err);
          throw err;
        }

        return false;
      } finally {
        this.testing = false;
      }

    },
    async getRenderedBody() {
       try {
        this.fetchingBody = true;
        const apiData = await ApiService.get("/api/admin/marketing/campaigns/render/" + this.$route.params.campaignId);
        this.renderedBody = apiData.data;
        this.bodyTab = 'email';
        return true;
      } catch (err) {
        this.testing = false;

        if (err.response) {
          notifyError('Error getting rendered email ', err);
        } else {
          console.log("Error: ", err);
          throw err;
        }

        return false;
      } finally {
        this.fetchingBody = false;
      }
    },

    async sendTest() {

      this.testing = true;

      try {
        this.testing = true;
        await ApiService.post("/api/admin/marketing/campaigns/test", {
          campaign_id: this.$route.params.campaignId,
          email: this.testEmail,
        });

        notifySuccess('Sent test email to ' + this.testEmail);
        return true;

      } catch (err) {
        this.testing = false;

        if (err.response) {
          notifyError('Error sending test email ', err);
        } else {
          console.log("Error: ", err);
          throw err;
        }

        return false;
      } finally {
        this.testing = false;
      }

    },

  }

}
</script>
